import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import {
  clearStoredEmail,
  getEmail,
  setStoredEmail,
} from 'store/slices/userSessionSlice';
import { getFormValidationStatus } from 'utils/form.util';
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';


// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  email: StringType()
    .isRequired('Please enter a valid email.')
    .isEmail('Please enter a valid email.'),
  password: StringType().isRequired('Please enter your password.'),
});

const INIT_FORM = { email: '', password: '', saveEmail: [] };

export default function useSignIn() {
  const [formValue, setFormValue] = useState<any>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  // get stored email
  const storedEmail = useAppSelector(getEmail);

  const dispatch = useAppDispatch();

  // set stored login email in form state
  useEffect(() => {
    let mounted = true;

    if (mounted && storedEmail)
      setFormValue({
        ...INIT_FORM,
        saveEmail: ['true'],
        email: storedEmail,
      });

    return () => {
      mounted = false;
    };
  }, [storedEmail]);

  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const submit = async () => {
    try {
      // verify that form is valid
      if (getFormValidationStatus(model.check(formValue))) {
        const auth = getAuth();

        // show spinner
        setIsLoading(true);

        // make copy of form value
        const payload = {
          email: formValue.email,
          password: formValue.password,
        };

        // Remove saveEmail key - it is not needed for login api request
        // @ts-ignore
        delete payload.saveEmail;

        setIsLoading(true);

        // set persistance to data
        await setPersistence(auth, browserLocalPersistence);

        // login using firebase auth
        await signInWithEmailAndPassword(
          auth,
          payload.email,
          payload.password,
        );

        // update stored email value if user checked off 'remember me'
        if (formValue.saveEmail.length > 0 && formValue.saveEmail[0])
          dispatch(setStoredEmail(formValue.email));
        else dispatch(clearStoredEmail()); // otherwise clear
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  return {
    formValue,
    isLoading,
    model,
    submit,
    setFormValue,
  };
}
