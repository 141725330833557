import React from 'react';
import { Uploader, Button as RsButton } from 'rsuite';
import { FileType } from 'rsuite/esm/Uploader';
import styled from 'styled-components';
import useIsMobile from 'hooks/useIsMobile';
import { COLOR_PRIMARY } from 'constants/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from 'store/hooks';
import { getIdToken } from 'firebase/auth';

const ContainerDragger = styled.div`
  height: 200px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const FileDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileContainer = styled.div`
  height: 200px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 25px;

  button {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR_PRIMARY};
    color: #fff;
    font-size: 21px;
  }
`;

interface IFileUploaderProps {
  isLoading: boolean;
  onError: any;
  onSuccess: any;
  onRemove: any;
  files: any[];
  url: string;
  setIsLoading: (b: boolean) => any;
}
export default function FileUploader(props: IFileUploaderProps) {
  const {
    setIsLoading,
    url,
    files,
    isLoading,
    onError,
    onRemove,
    onSuccess,
  } = props;

  const { isMobile } = useIsMobile();

  const onSuccessHandler = (
    response: any,
    file: FileType,
    event: ProgressEvent<EventTarget>,
  ) => {
    const element = document.querySelectorAll(`.file-upload`);

    if (element && element[0]) {
      const i = element.length - 1;
      // scroll to error input
      element[i].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    onSuccess(response, file, event);
  };

  if (isMobile) {
    return (
      <Uploader
        disabled={isLoading}
        onError={onError}
        multiple={false}
        onProgress={() => {
          setIsLoading(true);
        }}
        onSuccess={onSuccessHandler}
        onRemove={onRemove}
        draggable
        listType="picture-text"
        fileList={files}
        action={url}
        renderFileInfo={(file: FileType) => (
          <FileDescription className="file-upload">
            <a href={file.url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
          </FileDescription>
        )}
      >
        <MobileContainer>
          <RsButton>
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Tap here to upload a file'
            )}
          </RsButton>
        </MobileContainer>
      </Uploader>
    );
  }

  return (
    <Uploader
      disabled={isLoading}
      onError={onError}
      onSuccess={onSuccess}
      onRemove={onRemove}
      draggable
      listType="picture-text"
      fileList={files}
      action={url}
      multiple={false}
      renderFileInfo={(file: FileType) => (
        <FileDescription className="file-upload">
          <a href={file.url} target="_blank" rel="noreferrer">
            {file.name}
          </a>
        </FileDescription>
      )}
    >
      <ContainerDragger>
        <p>
          <b>Click or Drag files to this area to upload</b>
        </p>
        <RsButton>Select files...</RsButton>
      </ContainerDragger>
    </Uploader>
  );
}
