import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { useAppSelector } from 'store/hooks';
import { getEmail } from 'store/slices/userSessionSlice';
import {
  getFormValidationStatusAsync,
  scrollToFormErr,
} from 'utils/form.util';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import formValidation from 'constants/validation';
import { FormField } from 'components/Form/CustomForm';
import { PICKER_ETHNICITY } from 'constants/picker';
import SCHOOL_LIST from 'constants/schools';
import signUp from '../api/signUp';

export interface ISignUpForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  school: string;
  ethnicity: string;
  ethnicityOther: string;
  email: string;
  password: string;
  verifyPassword: string;
  // saveEmail: string[];
}

const { errorMessages: err } = formValidation;

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  firstName: StringType().isRequired(err.firstName),
  lastName: StringType().isRequired(err.lastName),
  phoneNumber: StringType().isRequired(err.phoneNumber),
  school: StringType().isRequired(err.school),
  ethnicity: StringType().isRequired(err.ethnicity),
  email: StringType().isRequired(err.email).isEmail(err.email),
  password: StringType()
    .isRequired(err.password)
    .minLength(6, 'Password must be 6 characters long.'),
  verifyPassword: StringType()
    .isRequired('Please confirm your Password.')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, err.verifyPassword),
});

const INIT_FORM: ISignUpForm = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  school: '',
  ethnicity: '',
  email: '',
  password: '',
  verifyPassword: '',
  ethnicityOther: '',
  // saveEmail: ['false'],
};

const fields: FormField[] = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    size: 'lg',
    data: null,
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    size: 'lg',
    data: null,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    size: 'lg',
    data: null,
  },
  {
    name: 'phoneNumber',
    type: 'phoneNumber',
    label: 'Phone Number',
    size: 'lg',
    data: null,
  },
  {
    name: 'school',
    type: 'autoComplete',
    label: 'School Name',
    size: 'lg',
    data: SCHOOL_LIST,
  },

  {
    name: 'ethnicity',
    type: 'select',
    label: 'Ethnicity',
    size: 'lg',
    data: {
      otherLabel: 'Enter your ethnicity',
      picker: PICKER_ETHNICITY,
      searchable: false
    },
  },

  {
    name: 'password',
    type: 'password',
    label: 'Password',
    size: 'lg',
    data: null,
  },
  {
    name: 'verifyPassword',
    type: 'password',
    label: 'Verify Password',
    size: 'lg',
    data: null,
  },
];

export default function useSignup() {
  const [formValue, setFormValue] = useState<ISignUpForm>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  // get stored email
  const storedEmail = useAppSelector(getEmail);

  // set stored login email in form state
  useEffect(() => {
    let mounted = true;

    if (mounted && storedEmail)
      setFormValue({
        ...INIT_FORM,
        // saveEmail: ['true'],
        email: storedEmail,
      });

    return () => {
      mounted = false;
    };
  }, [storedEmail]);

  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {

    try {
      const isValid = await getFormValidationStatusAsync(
        model.check(formValue),
      );
      // verify that form is valid
      if (isValid) {
        // pull out signup data without verify password field
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { verifyPassword: remove, ...signUpData } = formValue;
        const name = `${formValue.firstName} ${formValue.lastName}`;
        await signUp({ ...signUpData, name });

        // auto-login using firebase auth
        const auth = getAuth();
        await signInWithEmailAndPassword(
          auth,
          formValue.email,
          formValue.password,
        );

        // show spinner
        setIsLoading(true);
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: any) => {
    setFormValue(val);
  };
  
  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
