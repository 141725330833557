import styled from 'styled-components';
import React from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const FormCol = styled.div`
  flex: 1;
  display: block;
  min-height: 100vh;
  overflow: hidden;
  padding: 0px;
  padding-top: 75px;
  padding-bottom: 25px;
`;

function ForgotPassword() {
  return (
    <StyledContainer>
      <FormCol>
        <ForgotPasswordForm />
      </FormCol>
    </StyledContainer>
  );
}

export default ForgotPassword;
