const RAW_SCHOOL_LIST = [
  'ABC Adult',
  'ABC Secondary (Alternative)',
  'ABC Virtual Academy',
  'Aloha Elementary',
  'Artesia High',
  'Bragg Elementary',
  'Burbank (Luther) Elementary',
  'Carmenita Middle',
  'Carver (Charles J.) Elementary',
  'Cerritos Elementary',
  'Cerritos High',
  'Elliott (William F) Elementary',
  'Fedde (Pharis F.) Middle',
  'Furgeson (Venn W.) Elementary',
  'Gahr (Richard) High',
  'Gonsalves (Joe A.) Elementary',
  'Haskell (Pliny Fisk) Middle',
  'Hawaiian Elementary',
  'Juarez (Benito) Elementary',
  'Kennedy (John F.) Elementary',
  'Leal (Frank C.) Elementary',
  'Melbourne (Ella P.) Elementary',
  'Niemes (John H.) Elementary',
  'Nixon (Patricia) Elementary',
  'Palms Elementary',
  'Ross (Faye) Middle',
  'Stowers(Cecil B.) Elementary',
  'Tetzlaff (Martin B.) Middle',
  'Tracy (Wilbur) High (Continuation)',
  'Whitney (Gretchen) High',
  'Willow Elementary',
  'Wittmann (Helen) Elementary',
  'Assurance Learning Academy',
  'California Pacific Charter- Los Angeles',
  'Compass Charter Schools of Los Angeles',
  'Empower Generations',
  'High Desert',
  'iLead Agua Dulce',
  'iLEAD Hybrid',
  'iLEAD Online',
  'Meadowlark Elementary',
  'Method Schools, LA',
  'Mission Academy',
  'Options for Youth-Acton',
  'SIATech Academy South',
  'Vasquez High',
  'Alhambra High',
  'Brightwood Elementary',
  'Emery Park Elementary',

  'Granada Elementary',
  'Marguerita Elementary',
  'Mark Keppel High',
  'Martha Baldwin Elementary',
  'Monterey Highlands Elementary',
  'Park Elementary',
  'Ramona Elementary',
  'Repetto Elementary',
  'San Gabriel High',
  'William Northrup Elementary',
  'Ynez Elementary',
  'Antelope Valley ROP',
  'Academies of the Antelope Valley',
  'Antelope Valley Adult/Independent Study',
  'Antelope Valley High',
  'Desert Sands Charter',
  'Desert Winds Continuation High',
  'Eastside High',
  'Highland High',
  'Lancaster High',
  'Littlerock High',
  'Palmdale High',
  'Phoenix High Community Day',
  'Quartz Hill High',
  'R. Rex Parris High',
  'SOAR High (Students On Academic Rise)',
  'Synergy School of the Arts and Technology, Lancaster & Palmdale',
  'William J. (Pete) Knight High',
  'Arcadia High',
  'Baldwin Stocker Elementary',
  'Camino Grove Elementary',
  'First Avenue Middle',
  'Foothills Middle',
  'Highland Oaks Elementary',
  'Holly Avenue Elementary',
  'Hugo Reid Elementary',
  'Longley Way Elementary',
  'Rancho Learning Center (Alternative)',
  'Richard Henry Dana Middle',
  'Azusa Adult Education Center',
  'Azusa High',
  'Charles H. Lee Elementary',
  'Clifford D. Murray Elementary',
  'Gladstone Middle',
  'Henry Dalton Elementary',
  'Longfellow',
  'Magnolia Elementary',
  'Paramount Elementary',
  'Sierra High',
  'Valleydale Elementary',
  'Victor F. Hodge Elementary',
  'Baldwin Park Adult and Community Education',
  'Baldwin Park High',
  'BP STEM Academy',
  'Central Elementary',
  'Charles Bursch Elementary',
  'Charles D. Jones Middle',
  'De Anza Elementary',
  'Ernest R. Geddes Elementary',
  'Foster Elementary',
  'Kenmore Elementary',
  'Margaret Heath Elementary',
  'North Park Continuation High',
  'Olive Middle',
  'Opportunities for Learning - Baldwin Park',
  'Sierra Vista High',

  'Tracy Elementary',
  'Vineland Elementary',
  'Walnut Elementary',
  'Bassett Adult',
  'Bassett Senior High',
  'Don Julian Elementary',
  'Edgewood Academy',
  'J. E. Van Wig Elementary',
  'Nueva Vista Continuation High',
  'Sunkist Elementary',
  'Torch Middle',
  'Albert Baxter Elementary',
  'Bellflower Adult Education Center',
  'Bellflower Alternative Education Center',
  'Bellflower High',
  'Craig Williams Elementary',
  'Ernie Pyle Elementary',
  'Esther Lindstrom Elementary',
  'Frank E. Woodruff Elementary',
  'Intensive Learning Center',
  'Las Flores Home Education Independent Study Academy',
  'Mayfair High',
  'Ramona Elementary',
  'Somerset High',
  'Stephen Foster Elementary',

  'Washington Elementary',
  'Beverly Hills High',
  'Beverly Vista Middle',
  'El Rodeo Elementary',
  'Hawthorne Elementary',
  'Horace Mann Elementary',
  'Moreno High (Continuation)',
  'Adult Transition Program',
  'Allen Avenue Elementary',
  'Arma J. Shull Elementary',
  'Bonita District Adult',
  'Bonita High',
  'Chaparral High (Continuation)',
  'Fred Ekstrand Elementary',
  'Gladstone Elementary',
  'Grace Miller Elementary',
  'J. Marion Roynon Elementary',
  'La Verne Heights Elementary',
  'Lone Hill Middle',
  'Oak Mesa Elementary',
  'Ramona Middle',
  'San Dimas High',
  'Vista (Alternative)',
  'Bret Harte Elementary',
  'Burbank Adult',
  'Burbank High',
  'Burbank Unified Independent Learning Academy (BUILA)',
  'Burbank USD Community Day',
  'Burroughs High',
  'Dolores Huerta Middle',
  'George Washington Elementary',
  'Horace Mann',
  'Joaquin Miller Elementary',
  'John Muir Middle',
  'Luther Burbank Middle',
  'Magnolia Park',
  'Monterey High (Continuation)',
  'Providencia Elementary',
  'R. L. Stevenson Elementary',
  'Ralph Emerson Elementary',
  'Theodore Roosevelt Elementary',

  'Walt Disney Elementary',
  'William McKinley Elementary',
  'California Advancing Pathways for Students in Los Angeles County',
  'Castaic Elementary',
  'Castaic Middle',
  'Live Oak Elementary',
  'Non-RIS Preschool CMS',
  'Northlake Hills Elementary',
  'Centinela Valley Adult',
  'Centinela Valley Independent Study',
  'Family First Charter',
  'Hawthorne High',
  'Lawndale High',
  'Leuzinger High',
  'New Opportunities Charter',
  'R. K. Lloyde High',
  'Arrow High (Continuation)',
  'Badillo Elementary',
  'Bridges Community Day',
  'Cedargrove Elementary',
  'Charter Oak Adult Education',
  'Charter Oak High',
  'Glen Oak Elementary',
  'Oak Knoll Alternative',
  'Royal Oak Middle',

  'Willow Elementary',
  'Chaparral Elementary',
  'Claremont Adult',
  'Claremont High',
  'Condit Elementary',
  'Danbury Special Education',
  'El Roble Intermediate',
  'Mountain View Elementary',
  'Oakmont Elementary',
  'San Antonio High (Continuation)',
  'Sumner Elementary',
  'Sycamore Elementary',
  'Vista del Valle Elementary',
  'Anderson Elementary',
  'Animo Compton Charter',
  'Barack Obama Charter',
  'Bunche Middle',
  'Bursch Elementary',
  'Carver Elementary',
  'Centennial High',
  'Cesar Chavez Continuation High',
  'Clinton, William Jefferson',
  'Compton Adult',
  'Compton Community Day High',
  'Compton Community Day Middle',
  'Compton Early College High',
  'Compton High',
  'Compton STEP',
  'Compton Virtual Academy',
  'Davis Middle',
  'Dickison Elementary',
  'Dominguez High',
  'Emerson Elementary',
  'Enterprise Middle',

  'Ingenium Clarion Charter Middle',
  'ISANA Achernar Academy',

  'Kelly Elementary',
  'KIPP Compton Community',
  'Laurel Street Elementary',
  'Lifeline Education Charter',
  'Longfellow Elementary',
  'Martin Luther King Elementary',
  'Mayo Elementary',

  'Ralph Bunche Elementary',
  'Robert F. Kennedy Elementary',
  'Ronald E. McNair Elementary',

  'Rosecrans Elementary',
  'Thurgood Marshall',
  'Tibby Elementary',
  "Today's Fresh Start-Compton",
  'Walton Middle',

  'Whaley Middle',
  'Willowbrook Middle',
  'Compton Unified ROP',
  'Barranca Elementary',
  'Ben Lomond Elementary',
  'Covina High',
  "Covina-Valley Children's Center",
  'Covina-Valley Learning Options Academy',
  'Cypress Elementary',
  'Fairvalley High (Continuation)',
  'Grovecenter Elementary',
  'Las Palmas Middle',
  'Manzanita Elementary',
  'Merwin Elementary',
  'Mesa Elementary',
  'Northview High',
  'Rowland Avenue Elementary',

  'South Hills High',
  'Traweek Middle',
  'Tri-Community Adult Education',
  'Workman Avenue Elementary',
  'Culver City Adult',
  'Culver City High',
  'Culver City Middle',
  'Culver City Unified School District iAcademy',
  'Culver Park High',
  'El Marino Elementary',
  'El Rincon Elementary',
  'Farragut Elementary',
  'La Ballona Elementary',
  'Linwood E. Howe Elementary',
  'Alameda Elementary',
  'Carpenter (C. C.) Elementary',
  'Columbus (Christopher) High',
  'Doty (Wendy Lopour) Middle',
  'Downey Adult',
  'Downey High',
  'Downey Unified Virtual Academy at Lynn L. Pace Education Center',
  'Gallatin Elementary',
  'Gauldin (A.L.) Elementary',
  'Griffiths (Gordon) Middle',
  'Imperial Elementary',
  'Lewis (Ed C.) Elementary',
  'Old River Elementary',
  'Price (Maude) Elementary',
  'Rio Hondo Elementary',
  'Rio San Gabriel Elementary',
  'Stauffer (Mary R.) Middle',
  'Sussman (Edward A.) Middle',
  'Unsworth (Edith) Elementary',
  'Ward (E. W.) Elementary',
  'Warren (Earl) High',
  'Williams (Spencer V.) Elementary',
  'Woodruff Academy',
  'Beardslee Dual Language Immersion Academy (PK-8)',
  'California School of the Arts - San Gabriel Valley',
  'Duarte High',
  'Maxwell Academy (PK-8)',
  'Mt. Olive Innovation and Technology High',
  'Opportunities for Learning - Duarte',
  'Options For Youth - Duarte, Inc',
  'Royal Oaks STEAM Academy (K-8)',
  'Valley View Academy of Technology and Creative Learning (TK-6)',
  'Ceres Elementary',
  'East Whittier Middle',
  'Evergreen Elementary',
  'Granada Middle',
  'Hillview Middle',
  'La Colima Elementary',
  'Laurel Elementary',
  'Leffingwell Elementary',
  'Mulberry Elementary',
  'Murphy Ranch Elementary',
  'Ocean View Elementary',
  'Orchard Dale Elementary',
  'Scott Avenue Elementary',

  'Eastside Academy/Transitional Learning Center',
  'Eastside Elementary',
  'Enterprise Elementary',
  'Gifford C. Cole Middle',
  'Tierra Bonita Elementary',
  'Cherrylee Elementary',
  'Cleminson Elementary',
  'Columbia Elementary',
  'Cortada Elementary',

  'Gidley Elementary',
  'Legore Elementary',
  'New Lexington Elementary',
  'Potrero Elementary',
  'Rio Hondo Elementary',

  'Shirpser Elementary',
  'Thompson Elementary',
  'Wilkerson Elementary',
  'Wright Elementary',
  'Arroyo High',
  'El Monte High',
  'El Monte Union High School Community Day',
  'El Monte/Rosemead Adult',
  'Fernando R. Ledesma Continuation High',
  'Mountain View High',
  'Rosemead High',
  'South El Monte High',
  'Birney Tech Academy',
  'Durfee Elementary',
  'Early Learning Program',
  'El Rancho Adult',
  'El Rancho High',
  'Magee Academy of Arts & Sciences',
  'North Park Academy of the Arts',
  'North Ranchito Elementary',
  'Rio Vista Elementary',
  'Rivera Elementary',
  'Rivera Middle',
  'Ruben Salazar Continuation',
  'South Ranchito Dual Language Academy',
  'STEAM Academy @ Burke',
  'Valencia Academy of the Arts',
  'Arena High (Continuation)',
  'Center Street Elementary',
  'El Segundo High',
  'El Segundo Middle',
  'Richmond Street Elementary',
  'Bitely (Arlene) Elementary',
  'Dewey Avenue Elementary',
  'Duff Language Magnet Academy',
  'Emerson (Ralph Waldo) Elementary',
  'Garvey (Richard) Intermediate',
  'Hillcrest Elementary',
  'Marshall Early Education Center',
  'Monterey Vista Elementary',
  'Rice (Eldridge) Elementary',
  'Sanchez (George I.) Elementary',
  'Temple (Roger W.) Intermediate',
  'Willard (Frances E.) Elementary',
  'Abraham Lincoln Elementary',
  'Anderson W. Clark Magnet High',
  'Balboa Elementary',
  'Benjamin Franklin Elementary',

  'Cloud Preschool',
  'College View',
  'Columbus Elementary',
  'Crescenta Valley High',
  'Daily (Allan F.) High (Continuation)',
  'Dunsmore Elementary',
  'Eleanor J. Toll Middle',
  'Glendale High',
  'Glenoaks Elementary',
  'Herbert Hoover High',

  'Jewel City Community Day',
  'John C. Fremont Elementary',
  'John Marshall Elementary',
  'John Muir Elementary',
  'La Crescenta Elementary',
  'Mark Keppel Elementary',

  'Mountain Avenue Elementary',
  'Pacific Avenue - Early Bird Preschool',
  'R. D. White Elementary',
  'Rosemont Middle',
  'Theodore Roosevelt Middle',
  'Thomas Jefferson Elementary',
  'Valley View Elementary',
  'Verdugo Academy',
  'Verdugo Woodlands Elementary',
  'Woodrow Wilson Middle',
  'Cullen Elementary',
  'Glendora Adult',
  'Glendora High',
  'Goddard Middle',
  'La Fetra Elementary',
  'Sandburg Middle',
  'Sellers Elementary',
  'Stanton Elementary',
  'Sutherland Elementary',
  'Whitcomb Continuation High',
  'Williams Educational Center',
  'Gorman Elementary',
  'Gorman Learning Center',
  'Baldwin Academy',
  'Bixby Elementary',
  'California Elementary',
  'Cedarlane Academy',
  'Del Valle Elementary',
  'Dibble Adult',
  'Fairgrove Academy',
  'Glen A. Wilson High',
  'Grandview, College Preparatory Academy',
  'Grazide Elementary',
  'Hacienda La Puente Adult Education',
  'Kwis Elementary',
  'La Puente High',
  'Lassalette',
  'Los Altos Elementary',
  'Los Altos High',
  'Los Molinos Elementary',
  'Los Robles Academy',
  'Mesa Robles',
  'Nelson Elementary',
  'Newton Middle',
  'Orange Grove Middle',
  'Palm Canyon',
  'Palm Elementary',
  'Puente Hills High',
  'Sierra Vista Middle',
  'Sparks Elementary',
  'Sparks Middle',
  'Sunset',
  'Sunset Elementary',
  'Valinda School of Academics',
  'Valley Alternative High (Continuation)',
  'Valley Community Day',
  'Wedgeworth Elementary',
  'William Workman High',
  'Willow Adult',
  'Wing Lane Elementary',
  'Workman Elementary',
  'Hart ROP',
  'Bud Carson Middle',
  'Eucalyptus',
  'Hawthorne Math and Science Academy',
  'Hawthorne Middle',
  'Jefferson',
  'Kornblum',
  'Prairie Vista Middle',
  'Ramona',
  'Washington',
  'York',
  'Zela Davis',
  'Hermosa Valley Elementary',
  'Hermosa View Elementary',
  'Hermosa Vista',
  'Hughes-Elizabeth Lakes',
  'Animo Inglewood Charter High',
  'Bennett/Kew P-8 Leadership Academy of Excellence',
  'Beulah Payne P-8 STEAM Academy',
  'Centinela TK-8 Elementary',
  'City Honors International Preparatory High',
  'Crozier (George W.) Middle',
  'Frank D. Parent TK-8',
  'Grace Hopper STEM Academy',
  'Highland Elementary',
  'Hudnall (Claude) Elementary',
  'ICEF Inglewood Elementary Charter Academy',
  'Inglewood Career Technical Education, Adult Education, Alternative Education',
  'Inglewood Continuation High',
  'Inglewood High',
  'Kelso (William H.) Elementary',
  'La Tijera K-8 Academy of Excellence Charter',
  'Morningside High',
  'Oak Street Elementary',
  "Wilder's Preparatory Academy Charter",
  "Wilder's Preparatory Academy Charter Middle",
  'Woodworth-Monroe TK-8 Academy',
  'Worthington TK-6 Elementary',
  'Alpine Elementary',
  'Antelope Elementary',
  'Daisy Gibson Elementary',
  'Desert View Community Day',
  'Keppel Academy',
  'Lake Los Angeles Elementary',
  'Pearblossom Elementary',
  'Sage Oak Charter School- Keppel',
  'La Canada Elementary',
  'La Canada High',
  'Palm Crest Elementary',
  'Paradise Canyon Elementary',
  'Amargosa Creek Middle',
  'Desert View Elementary',
  'Discovery',
  'Early Learning',
  'El Dorado Elementary',
  'Endeavour Middle',
  'Fulton and Alsbury Academy of Arts and Engineering',
  'iLEAD Lancaster Charter',
  'Jack Northrop Elementary',
  'John and Jacquelyn Miller Elementary',
  'Joshua Elementary',
  'Lancaster Alternative and Virtual Academies',
  'Life Source International Charter',

  'Linda Verde Elementary',
  'Mariposa Elementary',

  'Nancy Cory Elementary',
  'New Vista Middle',
  'Piute Middle',
  'Promise Academy',
  'RISE',
  'Sierra Elementary',
  'Sunnydale Elementary',
  'The Leadership Academy',
  'West Wind Elementary',
  'Agoura High',
  'Alice C. Stelle Middle',
  'Arthur E. Wright Middle',
  'Bay Laurel Elementary',
  'Buttercup Pre-School',
  'Calabasas High',

  'Indian Hills Continuation High',
  'Las Virgenes Adult',
  'Las Virgenes Independent',
  'Lindero Canyon Middle',
  'Lupin Hill Elementary',
  'Mariposa School of Global Education',
  'Round Meadow Elementary',
  'Sumac Elementary',
  'White Oak Elementary',
  'Willow Elementary',
  'Yerba Buena Elementary',
  'Billy Mitchell Elementary',
  'Carson School',
  'Environmental Charter High - Lawndale',
  'F. D. Roosevelt Elementary',
  'Jane Addams Middle',
  'Lucille J. Smith Elementary',
  'Mark Twain Elementary',
  'Will Rogers Middle',
  'William Anderson Elementary',
  'William Green Elementary',
  'Animo Leadership High',
  'Buford Elementary',
  'Century Community Charter',
  'Dolores Huerta Elementary',
  'Felton Elementary',

  'Lennox Mathematics, Science and Technology Academy',
  'Lennox Middle',
  'Moffett Elementary',
  'Cresson Elementary',
  'Jersey Avenue Elementary',
  'Lake Center Middle',
  'Lakeland Elementary',
  'Lakeside Middle',
  'Lakeview Elementary',
  'Paddison Elementary',
  'Studebaker Elementary',
  'William W. Orr Elementary',
  'Addams Elementary',
  'Alvarado Elementary',
  'Avalon K-12',
  'Bancroft Middle',
  'Barton Elementary',
  'Birney Elementary',
  'Bixby Elementary',
  'Bobbie Smith Elementary',
  'Bryant Elementary',
  'Burbank Elementary',
  'Burcham Elementary',
  'Cabrillo High',
  'California Academy of Mathematics and Science',

  'Chavez Elementary',
  'Clear Passage Educational Center',
  'Cleveland Elementary',
  'Cubberley K-8',
  'Dooley Elementary',
  'Educational Partnership High',
  'Emerson Parkside Academy',
  'Ernest S. McBride, Sr. High',
  'Eunice Sato Academy of Math & Science',
  'Franklin Classical Middle',
  'Fremont Elementary',
  'Gant Elementary',

  'Gompers',
  'Grant Elementary',
  'Hamilton Middle',
  'Harte Elementary',
  'Helen Keller Middle',
  'Henry',
  'Holmes Elementary',
  'Hoover Middle',
  'Hudson',
  'Hughes Middle',
  'Intellectual Virtues Academy of Long Beach',
  'Jefferson Leadership Academies',
  'Jenny Oropeza Elementary',
  'Jessie Nelson Academy',
  'Jordan High',
  'Kettering Elementary',
  'King Elementary',
  'Lafayette Elementary',
  'Lakewood High',
  'Lincoln Elementary',
  'Lindbergh STEM Academy',
  'Lindsey Academy',
  'Long Beach School for Adults',

  'Los Cerritos Elementary',
  'Lowell Elementary',
  'MacArthur Elementary',
  'Madison Elementary',
  'Mann Elementary',
  'Marshall Academy of the Arts',

  'Millikan High',
  'Muir K-8',
  'Naples Elementary',
  'Newcomb Academy',
  'Olivia Nieto Herrera Elementary',
  'Polytechnic High',
  'Powell Academy for Success',
  'Prisk Elementary',
  'Reid High',
  'Renaissance High School for the Arts',
  'Richard D. Browning High',
  'Riley Elementary',
  'Robinson Academy',
  'Rogers Middle',

  'Signal Hill Elementary',
  'Stanford Middle',
  'Stephens Middle',
  'Stevenson Elementary',
  'Tincher Preparatory',
  'Twain Elementary',
  'Washington Middle',
  'Webster Elementary',
  'Whittier Elementary',
  'Willard Elementary',
  'Wilson High',
  'Long Beach Unified School District ROP',
  'Afflerbaugh-Paige Camp',
  'Alma Fuerte Public',
  'Animo City of Champions Charter High',
  'Aspire Antonio Maria Lugo Academy',
  'Aspire Ollin University Preparatory Academy',
  'Bridges Preparatory Academy',
  'Central Juvenile Hall',
  'Da Vinci RISE High',
  'Environmental Charter High - Gardena',
  'Environmental Charter Middle - Gardena',
  'Environmental Charter Middle - Inglewood',
  'Intellectual Virtues Academy',
  'International Polytechnic High',
  'Jardin de la Infancia',
  'KIPP Poder Public',
  'Kirby, Dorothy Camp',
  'L.A. County High School for the Arts',
  'Lashon Academy',
  'Lashon Academy City',
  'Los Padrinos Juvenile Hall',
  'Magnolia Science Academy',
  'Magnolia Science Academy 2',
  'Magnolia Science Academy 3',
  'Magnolia Science Academy 5',
  'Nidorf, Barry J.',
  'Odyssey Charter',
  'Renaissance County Community',
  'Road to Success Academy at Campus Kilpatrick',
  'Rockey, Glenn Camp',
  'Russell Westbrook Why Not? High',
  'Russell Westbrook Why Not? Middle',
  'Soleil Academy Charter',
  'The SEED School of Los Angeles County',
  'Valiente College Preparatory Charter',
  'We the People High',
  'Los Angeles County ROP',
  'Abraham Lincoln Senior High',
  'Abram Friedman Occupational',
  'Academia Moderna',
  'Academies of Education and Empowerment at Carson High',
  'Academy for Enriched Sciences',
  'Academy of Medical Arts at Carson High',
  'Accelerated',
  'Accelerated Charter Elementary',
  'Adult Education Virtual Academy',
  'Aggeler Community Day',
  'Alain Leroy Locke College Preparatory Academy',
  'Albert Einstein Continuation',
  'Albion Street Elementary',
  'Aldama Elementary',
  'Alexander Fleming Middle',
  'Alexander Hamilton Senior High',
  'Alexandria Avenue Elementary',
  'Alfonso B. Perez Special Education Center',
  'Alfred B. Nobel Charter Middle',
  'Allesandro Elementary',
  'Alliance Cindy and Bill Simon Technology Academy High',
  'Alliance College-Ready Middle Academy 12',
  'Alliance College-Ready Middle Academy 4',
  'Alliance College-Ready Middle Academy 8',
  'Alliance Collins Family College-Ready High',
  'Alliance Dr. Olga Mohan High',
  'Alliance Gertz-Ressler Richard Merkin 6-12 Complex',
  'Alliance Jack H. Skirball Middle',
  'Alliance Judy Ivie Burton Technology Academy High',
  'Alliance Kory Hunter Middle',
  'Alliance Leichtman-Levine Family Foundation Environmental Science High',
  'Alliance Marc & Eva Stern Math and Science',
  'Alliance Margaret M. Bloomfield Technology Academy High',
  'Alliance Marine - Innovation and Technology 6-12 Complex',
  'Alliance Morgan McKinzie High',
  "Alliance Ouchi-O'Donovan 6-12 Complex",
  'Alliance Patti And Peter Neuwirth Leadership Academy',
  'Alliance Piera Barbaglia Shaheen Health Services Academy',
  'Alliance Renee and Meyer Luskin Academy High',
  'Alliance Susan and Eric Smidt Technology High',
  'Alliance Ted K. Tajima High',
  'Alliance Tennenbaum Family Technology High',
  'Alliance Virgil Roberts Leadership Academy',
  'Alta California Elementary',
  'Alta Loma Elementary',
  'Amanecer Primary Center',
  'Ambassador School-Global Education',
  'Ambassador-Global Leadership',
  'Ambler Avenue Elementary',
  'Amelia Earhart Continuation',
  'Amestoy Elementary',
  'Anahuacalmecac International University Preparatory of North America',
  'Anatola Avenue Elementary',
  'Andasol Avenue Elementary',
  'Andres and Maria Cardenas Elementary',
  'Andrew Carnegie Middle',
  "Angel's Gate (Continuation)",
  'Angeles Mesa Elementary',
  'Animo Ellen Ochoa Charter Middle',
  'Animo Florence-Firestone Charter Middle',
  'Animo Jackie Robinson High',
  'Animo James B. Taylor Charter Middle',
  'Animo Jefferson Charter Middle',
  'Animo Legacy Charter Middle',
  'Animo Mae Jemison Charter Middle',
  'Animo Pat Brown',
  'Animo Ralph Bunche Charter High',
  'Animo South Los Angeles Charter',
  'Animo Venice Charter High',
  'Animo Watts College Preparatory Academy',
  'Ann Street Elementary',
  'Annalee Avenue Elementary',
  'Annandale Elementary',
  'Apperson Street Elementary',
  'Aragon Avenue Elementary',
  'Ararat Charter',
  'Arleta High',
  'Arlington Heights Elementary',
  'Arminta Street Elementary',
  'Arroyo Seco Museum Science',
  'Arts In Action Community Charter',
  'Arts in Action Community Middle',
  'Ascot Avenue Elementary',
  'Aspire Centennial College Preparatory Academy',
  'Aspire Firestone Academy Charter',
  'Aspire Gateway Academy Charter',
  'Aspire Inskeep Academy Charter',
  'Aspire Juanita Tate Academy Charter',
  'Aspire Junior Collegiate Academy',
  'Aspire Pacific Academy',
  'Aspire Slauson Academy Charter',
  'Aspire Titan Academy',
  'Atwater Avenue Elementary',
  'Audubon Middle',
  'Augustus Hawkins High',
  'Aurora Elementary',
  'Avalon Gardens Elementary',
  'Balboa Gifted/High Ability Magnet Elementary',
  'Baldwin Hills Elementary',
  'Bandini Street Elementary',
  'Barack Obama Global Preparation Academy',
  'Barton Hill Elementary',
  'Bassett Street Elementary',
  'Beachy Avenue Elementary',
  'Beckford Charter for Enriched Studies',
  'Beethoven Street Elementary',
  'Bell Senior High',
  'Bellingham Elementary',
  'Belmont Senior High',
  'Belvedere Elementary',
  'Belvedere Middle',
  'Benjamin Banneker Career and Transition Center',
  'Benjamin Franklin Senior High',
  'Berendo Middle',
  'Berenece Carlson Home Hospital',
  'Bert Corona Charter',
  'Bert Corona Charter High',
  'Bertrand Avenue Elementary',
  'Betty Plasencia Elementary',
  'Birdielee V. Bright Elementary',
  'Birmingham Community Charter High',
  'Blythe Street Elementary',
  'Bonita Street Elementary',
  'Boyle Heights Continuation',
  'Boyle Heights Science, Technology, Engineering and Math Magnet',
  'Boys Academic Leadership Academy',
  'Braddock Drive Elementary',
  'Brainard Elementary',
  'Breed Street Elementary',
  'Brentwood Science',
  'Bret Harte Preparatory Middle',
  'Bridge Street Elementary',
  'Broad Avenue Elementary',
  'Broadacres Avenue Elementary Visual & Performing Arts Magnet',
  'Broadway Elementary',
  'Brockton Avenue Elementary',
  'Brooklyn Avenue',
  'Bryson Avenue Elementary',
  'Buchanan Street Elementary',
  'Budlong Avenue Elementary',
  'Burbank Boulevard Elementary',
  'Burton Street Elementary',
  'Bushnell Way Elementary',
  'Cabrillo Avenue Elementary',
  'Cahuenga Elementary',
  'Cal Burke High',
  'Calabash Charter Academy',
  'Calahan Street Elementary',
  'California Creative Learning Academy',
  'California Creative Learning Academy MS',
  'Calvert Charter for Enriched Studies',
  'Camellia Avenue Elementary',
  'Camino Nuevo Charter Academy',
  'Camino Nuevo Charter Academy #2',
  'Camino Nuevo Charter Academy #4',
  'Camino Nuevo Elementary #3',
  'Camino Nuevo High #2',
  'Canfield Avenue Elementary',
  'Canoga Park Elementary',
  'Canoga Park Middle',
  'Canoga Park Senior High',
  'Cantara Street Elementary',
  'Canterbury Avenue Elementary',
  'Canyon Charter Elementary',
  'Capistrano Avenue Elementary',
  'Carlos Santana Arts Academy',
  'Caroldale Learning Community',
  'Carpenter Community Charter',
  'Carson Senior High',
  'Carson Street Elementary',
  'Carson-Gore Academy of Environmental Studies',
  'Carthay Elementary Of Environmental Studies Magnet',
  'Castelar Street Elementary',
  'Castle Heights Elementary',
  'Castlebay Lane Charter',
  'CATCH Prep Charter High, Inc.',
  'Catskill Avenue Elementary',
  'CDS Elementary',
  'CDS Secondary',
  'Center for Advanced Learning',
  'Central City Value',
  'Central High',
  'Century Park Elementary',
  'Cesar Chavez Elementary',
  'Cesar E. Chavez Learning Academies-Academy of Scientific Exploration (ASE)',
  'Cesar E. Chavez Learning Academies-Arts/Theatre/Entertain Mag',
  'Cesar E. Chavez Learning Academies-Social Justice Humanitas Academy',
  'Cesar E. Chavez Learning Academies-Technology Preparatory Academy',
  'CHAMPS - Charter HS of Arts-Multimedia & Performing',
  'Chandler Elementary',
  'Chapman Elementary',
  'Charles Drew Middle',
  'Charles H. Kim Elementary',
  'Charles Leroy Lowman Special Education and Career Transition Center',
  'Charles Maclay Middle',
  'Charles W. Barrett Elementary',
  'Charles White Elementary',
  'Charnock Road Elementary',
  'Chase Street Elementary',
  'Chatsworth Charter High',
  'Chatsworth Park ES Urban Planning/Community Development Magnet',
  'Cheremoya Avenue Elementary',
  'Chester W. Nimitz Middle',
  'Cheviot Hills Continuation',
  "CHIME Institute's Schwarzenegger Community",
  'Christopher Dena Elementary',
  'Cienega Elementary',
  'Cimarron Avenue Elementary',
  'Citizens of the World Charter School East Valley',
  'Citizens of the World Charter School Hollywood',
  'Citizens of the World Charter School Mar Vista',
  'Citizens of the World Charter School Silver Lake',
  'Citizens of the World Charter School West Valley',
  'City Language Immersion Charter',
  'City of Angels',
  'City Terrace Elementary',
  'Clifford Street Elementary Math & Technology Magnet',
  'Clover Avenue Elementary',
  "Coeur D'Alene Avenue Elementary",
  'Cohasset Street Elementary',
  'Coldwater Canyon Elementary',
  'Colfax Charter Elementary',
  'Coliseum Street Elementary',
  'Collegiate Charter High School of Los Angeles',
  'Columbus Avenue',
  'Commonwealth Avenue Elementary',
  'Communication and Technology at Diego Rivera Learning Complex',
  'Community Magnet Charter Elementary',
  'Compton Avenue Elementary',
  'Computer Science Virtual Academy',
  'Contreras Learning Center-Academic Leadership Community',
  'Contreras Learning Center-Los Angeles School of Global Studies',
  'Contreras Learning Center-School of Social Justice',
  'Corona Avenue Elementary',
  'Cowan Avenue Elementary',
  'Crenshaw Science, Technology, Engineering, Math and Medicine Magnet',
  'Crescent Heights Boulevard Elementary',
  'Crestwood Street STEAM Magnet',
  'Crete Academy',
  'Crown Preparatory Academy',
  'Dahlia Heights Elementary',
  'Dan M. Issacs Avalon High',
  'Daniel Pearl Journalism & Communications Magnet',
  'Daniel Webster Middle',
  'Danny J. Bakewell, Sr., Primary Center',
  'Danube Avenue Elementary',
  'Darby Avenue Elementary',
  'Dayton Heights Elementary',
  'Dearborn Elementary Charter Academy',
  'Del Amo Elementary',
  'Delevan Drive Elementary',
  'Denker Avenue Elementary',
  'Diane S. Leichman Career Preparatory and Transition Center',
  'Diego Rivera Learning Complex Green Design STEAM Academy',
  'Discovery Charter Preparatory #2',
  'Dixie Canyon Community Charter',
  'Dolores Huerta Elementary',
  'Dolores Street Elementary',
  'Dominguez Elementary',
  'Dorothy V. Johnson Community Day',
  'Dorris Place Elementary',
  'Downtown Business High',
  'Downtown Value',
  'Dr. James Edward Jones Primary Center',
  'Dr. Julian Nava Learning Academy',
  'Dr. Lawrence H. Moore Math, Science, Technology Academy',
  'Dr. Maya Angelou Community High',
  'Dr. Owen Lloyd Knox Elementary',
  'Dr. Richard A. Vladovic Harbor Teacher Preparation Academy',
  'Dr. Sammy Lee Medical and Health Science Magnet Elementary',
  'Dr. Theodore T. Alexander Jr. Science Center',
  'Dyer Street Elementary',
  'E. Manfred Evans Community Adult',
  'Eagle Rock Elementary',
  'Eagle Rock High',
  'Eagle Tree Continuation',
  'Early College Academy-LA Trade Tech College',
  'East Los Angeles Occupational',
  'East Los Angeles Renaissance Academy at Esteban E. Torres High No. 2',
  'East Valley Senior High',
  'Eastman Avenue Elementary',
  'Ednovate - Brio College Prep',
  'Ednovate - East College Prep',
  'Ednovate - Esperanza College Prep',
  'Ednovate - South LA College Prep',
  'Ednovate - USC Hybrid High College Prep',
  'Edward R. Roybal Learning Center',
  'Edwin Markham Middle',
  'El Camino Real Charter High',
  'El Dorado Avenue Elementary',
  'El Oro Way Charter For Enriched Studies',
  'El Rio Community',
  'El Sereno Elementary',
  'El Sereno Middle',
  'Elizabeth Learning Center',
  'Ellen Ochoa Learning Center',
  'Ellington (Duke) High (Continuation)',
  'Elysian Heights ES Arts Magnet',
  'Emelita Street Elementary',
  'Emerson Community Charter',
  'Enadia Way Technology Charter',
  'Encino Charter Elementary',
  'Engineering and Technology Academy at Esteban E. Torres High No. 3',
  'Equitas Academy #2',
  'Equitas Academy #3 Charter',
  'Equitas Academy 4',
  'Equitas Academy 5',
  'Equitas Academy 6',
  'Equitas Academy Charter',
  'Ernest Lawrence Middle',
  'Ernest P. Willenberg Special Education Center',
  'Erwin Elementary',
  'Eshelman Avenue Elementary',

  'Esteban Torres East LA Performing Arts Magnet',
  'Estrella Elementary',
  'Euclid Avenue Elementary',
  'Everest Value',
  'Evergreen Avenue Elementary',
  'Evergreen Continuation',
  'Extera Public',
  'Extera Public School No. 2',
  'Fair Avenue Elementary',
  'Fairburn Avenue Elementary',
  'Fairfax Senior High',
  'Farmdale Elementary',
  'Felicitas and Gonzalo Mendez High',
  'Fenton Avenue Charter',
  'Fenton Charter Leadership Academy',
  'Fenton Primary Center',
  'Fenton STEM Academy: Elementary Center for Science Technology Engineering and Mathematics',
  'Fernangeles Elementary',
  'Fifteenth Street Elementary',
  'Fifty-Fourth Street Elementary',
  'Fifty-Ninth Street Elementary',
  'Fifty-Second Street Elementary',
  'Figueroa Street Elementary',
  'First Street Elementary',
  'Fishburn Avenue Elementary',
  'Fletcher Drive Elementary',
  'Florence Avenue Elementary',
  'Florence Griffith Joyner Elementary',
  'Florence Nightingale Middle',
  'Ford Boulevard Elementary',
  'Forty-Ninth Street Elementary',
  'Forty-Second Street Elementary',
  'Foshay Learning Center',
  'Fourth Street Elementary',
  'Fourth Street Primary Center',
  'Francisco Bravo Medical Magnet High',
  'Francisco Sepulveda Middle',
  'Frank del Olmo Elementary',
  'Franklin Avenue Elementary',
  'Frida Kahlo High',
  'Fries Avenue Elementary',
  'Fullbright Avenue Elementary',
  'Gabriella Charter',
  'Gabriella Charter 2',
  'Garden Grove Elementary',
  'Gardena Elementary',
  'Gardena Senior High',
  'Gardner Street Elementary',
  'Garvanza Elementary',
  'Garza (Carmen Lomas) Primary Center',
  'Gaspar De Portola Charter Middle',
  'Gates Street Elementary',
  'Gault Street Elementary',
  'George De La Torre Jr. Elementary',
  'George Ellery Hale Charter Academy',
  'George K. Porter Middle',
  'George S. Patton Continuation',
  'George Washington Carver Middle',
  'George Washington Preparatory High',
  'Gerald A. Lawson Academy of the Arts, Mathematics and Science',
  'Germain Academy For Academic Achievement',
  'Gil Garcetti Learning Academy',
  'Girls Academic Leadership Academy, Dr. Michelle King School for Sci, Tech, Eng and Math',
  'Girls Athletic Leadership School Los Angeles',
  'Glassell Park ES Science, Technology, Engineering, Arts and Math Magnet',
  'Gledhill Street Elementary',
  'Glen Alta Elementary',
  'Glenfeliz Boulevard Elementary',
  'Glenn Hammond Curtiss Middle',
  'Glenwood Elementary',
  'Global Education Academy',
  'Global Education Academy 2',
  'Goethe International Charter',
  'Graham Elementary',
  'Granada Elementary',
  'Granada Hills Charter',
  'Grand View Boulevard Elementary',
  'Grant Elementary',
  'Grape Street Elementary',
  'Gratts Learning Academy for Young Scholars (GLAYS)',
  'Gridley Street Elementary',
  'Griffin Avenue Elementary',
  'Griffith Middle',
  'Grover Cleveland Charter High',
  'Gulf Avenue Elementary',
  'Haddon Avenue Elementary',
  'Halldale Elementary',
  'Hamlin Charter Academy',
  'Hancock Park Elementary',
  'Harbor City Elementary',
  'Harbor Occupational Center',
  'Harding Street Elementary',
  'Harmony Elementary',
  'Harold McAlister High (Opportunity)',
  'Harris Newmark Continuation',
  'Harrison Street Elementary',
  'Harry Bridges Span',
  'Hart Street Elementary',
  'Harvard Elementary',
  'Haskell Elementary Science Technology Engineering Arts And Mathematics (STEAM) Magnet',
  'Hawaiian Avenue Elementary',
  'Haynes Charter For Enriched Studies',
  'Hazeltine Avenue Elementary',
  'Helen Bernstein High',
  'Heliotrope Avenue Elementary',
  'Henry David Thoreau Continuation',
  'Henry T. Gage Middle',
  'Herrick Avenue Elementary',
  'Hesby Oaks Leadership Charter',
  'High Tech LA',
  'High Tech LA Middle',
  'Highland Park Continuation',
  'Hilda L. Solis Learning Academy School of Technology, Business and Education',
  'Hillcrest Drive Elementary',
  'Hillery T. Broadous Elementary',
  'Hillside Elementary',
  'Hobart Boulevard Elementary',
  'Hollenbeck Middle',
  'Hollywood Elementary',
  'Hollywood Senior High',
  'Holmes Avenue Elementary',
  'Hooper Avenue Elementary',
  'Hooper Avenue Primary Center',
  'Hoover Street Elementary',
  'Hope Street Elementary',
  'Horace Mann UCLA Community',
  'Hubbard Street Elementary',
  'Hubert Howe Bancroft Middle',
  'Humanitas Academy of Art and Technology at Esteban E. Torres High No. 4',
  'Humanities and Arts (HARTS) Academy of Los Angeles',
  'Humphreys Avenue Elementary',
  'Huntington Drive Elementary',
  'Huntington Park Elementary',
  'Huntington Park Senior High',
  'ICEF Innovation Los Angeles Charter',
  'ICEF View Park Preparatory Elementary',
  'ICEF View Park Preparatory High',
  'ICEF View Park Preparatory Middle',
  'ICEF Vista Elementary Academy',
  'ICEF Vista Middle Academy',
  'Independence Continuation',
  'Independence Elementary',
  'Ingenium Charter',
  'Ingenium Charter Middle',
  'International Studies Learning Center at Legacy High School Complex',
  'Invictus Leadership Academy',
  'Iovine and Young Center',
  'ISANA Cardinal Academy',
  'ISANA Himalia Academy',
  'ISANA Nascent Academy',
  'ISANA Octavia Academy',
  'ISANA Palmati Academy',
  'Ivanhoe Elementary',
  'Ivy Academia',
  'Ivy Bound Academy of Math, Science, and Technology Charter Middle',
  'Jack London Community Day',
  'Jack London Continuation',
  'Jaime Escalante Elementary',
  'James A. Garfield Senior High',
  'James J. McBride Special Education Center',
  'James Jordan Middle',
  'James Madison Middle',
  'James Monroe High',
  'Jane Addams Continuation',
  'Joaquin Miller Career and Transition Center',
  'John A. Sutter Middle',
  'John Adams Middle',
  'John B. Monlux Elementary',
  'John Burroughs Middle',
  'John C. Fremont Senior High',
  'John F. Kennedy High',
  'John H. Francis Polytechnic',
  'John H. Liechty Middle',
  'John Hope Continuation',
  'John Marshall Senior High',
  'John Muir Middle',
  'John R. Wooden High',
  'John W. Mack Elementary',
  'Johnnie Cochran, Jr., Middle',
  'Jordan High',
  'Joseph Le Conte Middle',
  'Joseph Pomeroy Widney Career Preparatory and Transition Center',
  'Judith F. Baca Arts Academy',
  'Julie Korenstein Elementary',
  'Justice Street Academy Charter',
  'Katherine Johnson STEM Academy',
  'Kenter Canyon Elementary Charter',
  'Kentwood Elementary',
  'Kester Avenue Elementary',
  'King/Drew Medical Magnet High',
  'Kingsley Elementary',
  'KIPP Academy of Innovation',
  'KIPP Academy of Opportunity',
  'KIPP Comienza Community Prep',
  'KIPP Corazon Academy',
  'KIPP Empower Academy',
  'KIPP Endeavor College Preparatory Charter',
  'KIPP Generations Academy',
  'KIPP Ignite Academy',
  'KIPP Iluminar Academy',
  'KIPP Los Angeles College Preparatory',
  'KIPP Philosophers Academy',
  'KIPP Promesa Prep',
  'KIPP Pueblo Unido',
  'KIPP Raices Academy',
  'KIPP Scholar Academy',
  'KIPP Sol Academy',
  'KIPP Vida Preparatory Academy',
  'Kittridge Street Elementary',
  'Knollwood Preparatory Academy',
  'La Salle Avenue Elementary',
  'Lafayette Park Primary Center',
  'Lake Balboa College Preparatory Magnet K-12',
  'Lake Street Primary',
  'Lanai Road Elementary',
  'Langdon Avenue Elementary',
  'Lankershim Elementary',
  'Larchmont Charter',
  'Lassen Elementary',
  'Latona Avenue Elementary',
  'Laurel Elementary',
  'Leadership & Public Service Virtual Academy',
  'Leapwood Avenue Elementary',
  'Learning by Design Charter',
  'Leland Street Elementary',
  'Lemay Street Elementary',
  'Lenicia B. Weemes Elementary',
  'Leo Politi Elementary',
  'Lexington Avenue Primary Center',
  'Libertas College Preparatory Charter',
  'Liberty Boulevard Elementary',
  'Liggett Street Elementary',
  'Lillian Street Elementary',
  'Limerick Avenue Elementary',
  'Linda Esperanza Marquez High A Huntington Park Institute of Applied Medicine',
  'Linda Esperanza Marquez High B LIBRA Academy',
  'Linda Esperanza Marquez High C School of Social Justice',
  'Lockhurst Drive Charter Elementary',
  'Lockwood Avenue Elementary',
  'Logan Academy of Global Ecology',

  'Lomita Math/Science/Technology Magnet',
  'Loren Miller Elementary',
  'Lorena Street Elementary',
  'Loreto Street Elementary',
  'Lorne Street Elementary',
  'Los Angeles Academy Middle',
  'Los Angeles Academy of Arts and Enterprise',
  'Los Angeles Center for Enriched Studies',
  'Los Angeles Elementary',
  'Los Angeles High School of the Arts',
  'Los Angeles Leadership Academy',
  'Los Angeles Leadership Primary Academy',
  'Los Angeles Senior High',
  'Los Angeles Technology Center',
  'Los Feliz Science/Tech/Engineer/Math/Medicine Magnet',
  'Louis Armstrong Middle',
  'Lovelia P. Flournoy Elementary',
  'Loyola Village Fine And Performing Arts Magnet',
  'Lucille Roybal-Allard Elementary',
  'Luther Burbank Middle',
  'MacArthur Park Elementary School for the Visual and Performing Arts',
  'Madison Elementary',
  'Magnolia Avenue Elementary',
  'Magnolia Science Academy 4',
  'Magnolia Science Academy 6',
  'Magnolia Science Academy 7',
  'Magnolia Science Academy Bell',
  'Main Street Elementary',
  'Malabar Street Elementary',
  'Manchester Avenue Elementary',
  'Manhattan Place Elementary',
  'Manual Arts Senior High',
  'Maple Primary Center',
  'Mar Vista Elementary',
  'Marguerite Poindexter LaMotte Elementary',
  'Marianna Avenue Elementary',
  'Marina del Rey Middle',
  'Mariposa-Nabi Primary Center',
  'Mark Twain Middle',
  'Marlton',
  'Marquez Charter',
  'Martha Escutia Primary Center',
  'Martin Luther King Jr. Elementary',
  'Marvin Elementary',
  'Mary McLeod Bethune Middle',
  'Math and Science College Preparatory',
  'Math, Science, & Technology Magnet Academy at Roosevelt High',
  'Matrix for Success Academy',
  'Maurice Sendak Elementary',
  'Maxine Waters Employment Prep Center',
  'Mayall Street Elementary',
  'Mayberry Street Elementary',
  'Maywood Academy High',
  'Maywood Center for Enriched Studies',
  'Maywood Elementary',
  'McKinley Avenue Elementary',
  'Melrose Avenue Elementary',
  'Melvin Avenue Elementary',
  'Menlo Avenue Elementary',
  'Mervyn M. Dymally High',
  'Metropolitan Continuation',
  'Meyler Street Elementary',
  'Michelle Obama Elementary',
  'Micheltorena Street Elementary',
  "Mid-City's Prescott School of Enriched Sciences",
  'Middle College High',
  'Middleton Primary Center',
  'Middleton Street Elementary',
  'Miles Avenue Elementary',
  'Miramonte Elementary',
  'Mission Continuation',
  'Moneta Continuation',
  'Monsenor Oscar Romero Charter Middle',
  'Montague Charter Academy',
  'Montara Avenue Elementary',
  'Monte Vista Street Elementary',
  'Monterey Continuation',
  'Morningside Elementary',
  'Morris K. Hamasaki Elementary',
  'Mountain View Elementary',
  'Mt. Gleason Middle',
  'Mt. Lukens Continuation',
  'Mt. Washington Elementary',
  'Multicultural Learning Center',
  'Multnomah Street Elementary',
  'Murchison Street Elementary',
  'N.E.W. Academy Canoga Park',
  'N.E.W. Academy of Science and Arts',
  'Napa Street Elementary',
  'Nathaniel Narbonne Senior High',
  'Nava College Preparatory Academy',
  'Nestle Avenue Charter',
  'Nevada Avenue Elementary',
  'Nevin Avenue Elementary',
  'New Designs Charter',
  'New Designs Charter School-Watts',
  'New Heights Charter',
  'New Horizons Charter Academy',
  'New Los Angeles Charter',
  'New Los Angeles Charter Elementary',
  'New Millennium Secondary',
  'New Open World Academy K-12',
  'New Village Girls Academy',
  'Newcastle Elementary',
  'Ninety-Fifth Street Elementary',
  'Ninety-Ninth Street Elementary',
  'Ninety-Second Street Elementary',
  'Ninety-Sixth Street Elementary',
  'Ninety-Third Street Elementary',
  'Ninth Street Elementary',
  'Noble Avenue Elementary',
  'Nora Sterry Elementary',
  'Normandie Avenue Elementary',
  'Normont Elementary',
  'North Hollywood Senior High',
  'North Valley Occupational Center',
  'Northridge Academy High',
  'Northridge Middle',
  'Norwood Street Elementary',
  'Nueva Vista Elementary',
  "O'Melveny Elementary",
  'Ocean Charter',
  'Odyssey Continuation',
  'Olive Vista Middle',
  'Oliver Wendell Holmes Middle',
  'Olympic Primary Center',
  'One Hundred Eighteenth Street',
  'One Hundred Eighty-Sixth Street Elementary',
  'One Hundred Fifty-Sixth Street Elementary',
  'One Hundred Fifty-Third Street',
  'One Hundred Ninth Street Elementary',
  'One Hundred Seventh Street Elementary',
  'One Hundred Sixteenth Street Elementary',
  'One Hundred Thirty-Fifth Street Elementary',
  'One Hundred Twelfth Street Elementary',
  'One Hundred Twenty-Second Street Elementary',
  'Open Charter Magnet',
  'Orchard Academies 2B',
  'Orchard Academies 2C',
  'Orthopaedic Hospital',
  'Orville Wright Engineering and Design Magnet',
  'Oscar De La Hoya Animo Charter High',
  'Osceola Street Elementary',
  'Our Community Charter',
  'Overland Avenue Elementary',
  'Owensmouth Continuation',
  'Oxnard Street Elementary',
  'Pacific Boulevard',
  'Pacoima Charter Elementary',
  'Pacoima Middle',
  'Palisades Charter Elementary',
  'Palisades Charter High',
  'Palms Elementary',
  'Palms Middle',
  'Panorama City Elementary',
  'Panorama High',
  'Para Los Niños - Evelyn Thurman Gratts Primary',
  'Para Los Niños Charter',
  'Para Los Niños Middle',
  'Park Avenue Elementary',
  'Park Western Place Elementary',
  'Parmelee Avenue Elementary',
  'Parthenia Academy of Arts and Technology',
  'Paseo del Rey Fundamental',
  'Patrick Henry Middle',
  'Paul Revere Charter Middle',
  'Performing Arts Community at Diego Rivera Learning Complex',
  'Phineas Banning Senior High',
  'Phoenix Continuation',
  'Pinewood Avenue Elementary',
  'Pio Pico Middle',
  'Plainview Academic Charter Academy',
  'Playa del Rey Elementary',
  'Playa Vista Elementary',
  'Plummer Elementary',
  'Point Fermin Elementary',
  'Pomelo Community Charter',
  'Port of Los Angeles High',
  'Porter Ranch Community',
  'PREPA TEC - Los Angeles',
  'President Avenue Elementary',
  'Primary Academy for Success',
  'Public Service Community at Diego Rivera Learning Complex',
  'PUC CALS Middle School and Early College High',
  'PUC Community Charter Elementary',
  'PUC Community Charter Middle and PUC Community Charter Early College High',
  'PUC Early College Academy for Leaders and Scholars (ECALS)',
  'PUC Excel Charter Academy',
  'PUC Inspire Charter Academy',
  'PUC Lakeview Charter Academy',
  'PUC Lakeview Charter High',
  'PUC Milagro Charter',
  'PUC Nueva Esperanza Charter Academy',
  'PUC Triumph Charter Academy and PUC Triumph Charter High',
  'Pueblo de Los Angeles Continuation',
  'Puente Charter',
  'Purche Avenue Elementary',
  'Queen Anne Place Elementary',
  'Quincy Jones Elementary',
  'Ramon C. Cortines School of Visual and Performing Arts',
  'Ramona Elementary',
  'Ramona Opportunity High',
  'Ranchito Avenue Elementary',
  'Rancho Dominguez Preparatory',
  'Raymond Avenue Elementary',
  'Renaissance Arts Academy',
  'Reseda Charter High',
  'Reseda Elementary',
  'Ricardo Lizarraga Elementary',
  'Richard A. Alonzo Community Day',
  'Richard E. Byrd Middle',

  'Richard N. Slawson Southeast OC',
  'Richard Riordan Primary Center',
  'Richland Avenue Elementary',

  'Rise Kohyang Elementary',
  'Rise Kohyang High',
  'Rise Kohyang Middle',
  'Ritter Elementary',
  'Riverside Drive Charter',
  'Robert E. Peary Middle',

  'Robert Frost Middle',
  'Robert Fulton College Preparatory',
  'Robert H. Lewis Continuation',
  'Robert Hill Lane Elementary',
  'Robert Louis Stevenson College and Career Preparatory',
  'Rockdale Visual & Performing Arts Magnet',
  'Rosa Parks Learning Center',
  'Roscoe Elementary',
  'Roscomare Road Elementary',
  'Rosemont Avenue Elementary',
  'Rosewood Avenue Elementary',
  'Rowan Avenue Elementary',
  'Roy Romer Middle',
  'Rudecinda Sepulveda Dodson Middle',
  'Russell Elementary',
  'Sal Castro Middle',
  'Sally Ride Elementary: A SMArT Academy',
  'Samuel Gompers Middle',
  'San Antonio Continuation',
  'San Antonio Elementary',
  'San Fernando Elementary',
  'San Fernando Institute of Applied Media',
  'San Fernando Middle',
  'San Fernando Senior High',
  'San Gabriel Avenue Elementary',
  'San Jose Street Elementary',
  'San Miguel Elementary',
  'San Pascual Elementary Science Technology Engineering Arts And Mathematics (STEAM) Magnet',
  'San Pedro Senior High',
  'San Pedro Street Elementary',
  'Santa Monica Boulevard Community Charter',
  'Santee Education Complex',
  'Sara Coughlin Elementary',
  'Saticoy Elementary',
  'Saturn Street Elementary',
  'Scholarship Prep - South Bay',
  'School for the Visual Arts and Humanities',
  'School of Business and Tourism at Contreras Learning Complex',
  'Science Academy STEM Magnet',
  'Science, Technology, Engineering, Arts and Mathematics at Legacy High School Complex',
  'Second Street Elementary',
  'Serrania Avenue Charter For Enriched Studies',
  'Seventh Street Elementary Arts Integration Magnet',
  'Seventy-Fifth Street Elementary',
  'Seventy-Fourth Street Elementary',
  'Sharp Avenue Elementary',
  'Shenandoah Street Elementary',
  'Sheridan Street Elementary',
  'Sherman Oaks Center for Enriched Studies',
  'Sherman Oaks Elementary Charter',
  'Shirley Avenue Elementary',
  'Short Avenue Elementary',
  'Sierra Park Elementary',
  'Sierra Vista Elementary',
  'Simon Rodia Continuation',
  'Sixth Avenue Elementary',
  'Sixty-First Street Elementary',
  'Sixty-Sixth Street Elementary',
  'Social Justice Leadership Academy Magnet at Esteban E. Torres High No 5',
  'Solano Avenue Elementary',
  'Sophia T. Salvin Special Education Center',
  'Soto Street Elementary',
  'Sotomayor Arts and Sciences Magnet',
  'South East High',
  'South Gate Middle',
  'South Gate Senior High',
  'South Park Elementary',
  'South Shores/CSUDH Visual and Performing Arts',
  'Southeast Middle',
  'Special Education-Infant/Preschool Program',
  'Stagg Street Elementary',
  'Stanford Avenue Elementary',
  'Stanford Primary Center',
  'Stanley Mosk Elementary',
  'State Street Elementary',
  'Stella Elementary Charter Academy',
  'Stella High Charter Academy',
  'Stella Middle Charter Academy',
  'STEM Academy at Bernstein High',
  'STEM Preparatory Elementary',
  'Stephen M. White Middle',
  'Stonehurst Avenue Elementary',
  'Stoner Avenue Elementary',
  'Stoney Point Continuation',
  'Strathern Street Elementary',
  'Sun Valley Magnet',
  'Sunland Elementary',
  'Sunny Brae Avenue Elementary',
  'Sunrise Elementary',
  'Superior Street Elementary',
  'Susan Miller Dorsey Senior High',
  'Sven Lokrantz Special Education Center',
  'Sylmar Biotech Health and Engineering Magnet',
  'Sylmar Charter High',
  'Sylmar Elementary',
  'Sylmar Leadership Academy',
  'Sylvan Park Elementary',
  'Synergy Charter Academy',
  'Synergy Kinetic Academy',
  'Synergy Quantum Academy',
  'Taft Charter High',
  'Taper Avenue Elementary',
  'Tarzana Elementary',
  'TEACH Academy of Technologies',
  'TEACH Preparatory Mildred S. Cunningham & Edith H. Morris Elementary',
  'TEACH Tech Charter High',
  'Telfair Avenue Elementary',
  'Tenth Street Elementary',
  'Teresa Hughes Elementary',
  'Theodore Roosevelt Senior High',
  'Third Street Elementary',
  'Thirty-Second Street USC Performing Arts',
  'Thomas A. Edison Middle',
  'Thomas Jefferson Senior High',
  'Thomas Riley High',
  'Thomas Starr King Middle School Film and Media Magnet',
  'Toland Way Elementary',
  'Toluca Lake Elementary',
  'Tom Bradley Global Awareness Magnet',
  'Topanga Elementary Charter',
  'Topeka Charter School For Advanced Studies',
  'Towne Avenue Elementary',
  'Tri-C Community Day',
  'Trinity Street Elementary',
  'Tulsa Street Elementary',
  'Tweedy Elementary',
  'Twentieth Street Elementary',
  'Twenty-Eighth Street Elementary',
  'Twenty-Fourth Street Elementary',
  'Two Hundred Thirty-Second Place',
  'UCLA Community K-12',
  'Ulysses S. Grant Senior High',
  'Union Avenue Elementary',
  'University High School Charter',
  'University Pathways Medical Magnet Academy',
  'University Pathways Public Service Academy',
  'University Preparatory Value High',
  'Utah Street Elementary',
  'Valerio Street Elementary',
  'Valley Academy of Arts and Sciences',
  'Valley Charter Elementary',
  'Valley Charter Middle',
  'Valley International Preparatory High',
  'Valley Oaks Center for Enriched Studies',
  'Valley View Elementary',
  'Valor Academy Elementary',
  'Valor Academy High',
  'Valor Academy Middle',
  'Van Deene Avenue Elementary',
  'Van Gogh Charter',
  'Van Ness Avenue Elementary',
  'Van Nuys Elementary',
  'Van Nuys Middle',
  'Van Nuys Senior High',
  'Vanalden Avenue Elementary',
  'Vaughn Next Century Learning Center',
  'Vena Avenue Elementary',
  'Venice Senior High',
  'Venice Skills Center',
  'Verdugo Hills Senior High',
  'Vermont Avenue Elementary',
  'Vernon City Elementary',
  'Victoria Avenue Elementary',
  'Victory Boulevard Elementary',
  'View Park Continuation',
  'Village Charter Academy',
  'Vine Street Elementary',
  'Vinedale College Preparatory Academy',
  'Vintage Math/Science/Technology Magnet',
  'Virgil Middle',
  'Virginia Road Elementary',
  'Virtual Academy of Business & Entrepreneurship',
  'Virtual Academy of International Studies/Humanities (World Languages and Cultures)',
  'Virtual Academy of the Arts & Entertainment',
  'Virtual Academy STEAM',
  'Vista Charter Middle',
  'Vista del Valle Dual Language Academy',
  'Vista Horizon Global Academy',
  'Vista Middle',
  'Visual and Performing Arts at Legacy High School Complex',
  'Vox Collegiate of Los Angeles',
  'Wadsworth Avenue Elementary',
  'Walgrove Avenue Elementary',
  'Wallis Annenberg High',
  'Walnut Park Elementary',
  'Walnut Park Middle A School of Social Justice and Service Learning',
  'Walnut Park Middle B Science, Technology, Engineering and Mathematics Academy',
  'Walter Reed Middle',
  'Warner Avenue Elementary',
  'Washington Irving Middle School Math, Music and Engineering Magnet',
  'Washington Primary Center',
  'Watts Learning Center',
  'Watts Learning Center Charter Middle',
  'Weigand Avenue Elementary',
  'Welby Way Charter Elementary School And Gifted-High Ability Magnet',
  'WESM Health/Sports Medicine',
  'West Adams Preparatory High',
  'West Athens Elementary',
  'West Hollywood Elementary',
  'West Valley Occupational Center',
  'West Vernon Avenue Elementary',
  'Western Avenue Technology Magnet',
  'Westminster Avenue Elementary',
  'Westport Heights Elementary',
  'Westside Global Awareness Magnet',
  'Westwood Charter Elementary',
  'White Point Elementary',
  'Whitman Continuation',
  'Whitney Young Continuation',
  'Wilbur Charter For Enriched Academics',
  'Will Rogers Continuation',
  'William J. Johnston Community Day',
  'William Jefferson Clinton Middle',
  'William Mulholland Middle',
  'William R. Anton Elementary',
  'William Tell Aggeler Opportunity High',
  'Willow Elementary',
  'Wilmington Middle Science, Technology, Engineering, Arts, Mathematics (STEAM) Magnet',
  'Wilmington Park Elementary',
  'Wilshire Crest Elementary',
  'Wilshire Park Elementary',
  'Wilton Place Elementary',
  'Windsor Hills Math Science',
  'Winnetka Avenue Elementary',
  'Wisdom Elementary',
  'WISH Academy High',
  'WISH Community',
  'Wonderland Avenue Elementary',
  'Woodcrest Elementary',
  'Woodlake Elementary Community Charter',
  'Woodland Hills Academy',
  'Woodland Hills Elementary Charter For Enriched Studies',
  'Woodlawn Avenue Elementary',
  'Woodrow Wilson Senior High',
  'YES Academy',
  'Yorkdale Elementary',
  'Young Oak Kim Academy',
  'Youth Opportunities Unlimited',
  'Zane Grey Continuation',
  'Los Angeles Unified School District ROCP',
  'Ada S. Nelson Elementary',
  'Aeolian Elementary',
  'Los Nietos Middle',
  'Rancho Santa Gertrudes Elementary',
  'Cesar Chavez Middle',
  'Helen Keller Elementary',
  'Hosler Middle',
  'Janie P. Abbott Elementary',

  'Lindbergh Elementary',
  'Lugo Elementary',
  'Lynwood Adult',
  'Lynwood High',
  'Marco Antonio Firebaugh High',
  'Mark Twain Elementary',
  'Pathway Independent Study',

  'Rosa Parks Elementary',
  'Thurgood Marshall Elementary',
  'Vista High',

  'Aurelia Pennekamp Elementary',
  'Grand View Elementary',
  'Manhattan Beach Middle',
  'Manhattan Beach Preschool',
  'Meadows Avenue Elementary',
  'Mira Costa High',
  'Opal Robinson Elementary',
  'Pacific Elementary',
  'Bradoaks Elementary Science Academy',
  'Canyon Early Learning Center',
  'Canyon Oaks High',
  'Clifton Middle',
  'Mayflower Elementary',
  'Monroe Elementary',
  'Monrovia Community Adult',
  'Monrovia High',
  'Mountain Park',
  'Plymouth Elementary',
  'Santa Fe Computer Science Magnet',
  'Wild Rose School of Creative',
  'Applied Technology Center',
  'Bandini Elementary',
  'Bell Gardens Adult',
  'Bell Gardens Elementary',
  'Bell Gardens High',
  'Bell Gardens Intermediate',
  'Bella Vista Elementary',
  'Cesar E. Chavez Elementary',
  'Eastmont Intermediate',
  'Ford Park Community Adult',

  'Garfield Elementary',
  'Greenwood Elementary',
  'Joseph A. Gascon Elementary',
  'La Merced Academy',
  'La Merced Intermediate',
  'Macy Intermediate',
  'Montebello Adult',
  'Montebello Community Day',
  'Montebello Gardens Elementary',
  'Montebello High',
  'Montebello Intermediate',
  'Montebello Park Elementary',
  'Potrero Heights Elementary',
  'Rosewood Park',
  'Schurr Community Adult',
  'Schurr High',
  'Suva Elementary',
  'Suva Intermediate',
  'Vail High (Continuation)',

  'Wilcox Elementary',
  'Winter Gardens Elementary',
  'Baker Elementary',
  'La Primaria Elementary',
  'Maxson Elementary',
  'Miramonte Elementary',

  'Parkview Elementary',
  'Twin Lakes Elementary',
  'Willard F. Payne Elementary',
  'Dr. J. Michael McGrath Elementary',
  'Meadows Elementary',
  'Newhall Elementary',
  'Oak Hills Elementary',
  'Old Orchard Elementary',
  'Peachland Avenue Elementary',
  'Pico Canyon Elementary',
  'Stevenson Ranch Elementary',
  'Valencia Valley Elementary',
  'Wiley Canyon Elementary',
  'Anna M. Glazier Elementary',
  'Arlie F. Hutchinson Middle',
  'Arturo Sanchez Elementary',
  'Corvallis Middle',
  'D. D. Johnston Elementary',
  'Earl E. Edmondson Elementary',
  'Eastwood Elementary',
  'El Camino High (Continuation)',
  'Escalona Elementary',
  'Foster Road Elementary',
  'Gardenhill Elementary',
  'John Dolland Elementary',
  'John Foster Dulles Elementary',
  'John H. Glenn High',
  'John H. Nuffer Elementary',
  'Julia B. Morrison Elementary',
  'La Mirada High',
  'La Pluma Elementary',
  'Loretta Lampton Elementary',
  'Los Alisos Middle',
  'Nettie L. Waite Middle',
  'New River Elementary',
  'Norwalk High',
  'Norwalk-La Mirada Adult',
  'Ramona Head Start/State Preschool',
  'Reginald M. Benton Middle',
  'Thomas B. Moffitt Elementary',
  'Antelope Valley Learning Academy',
  'Barrel Springs Elementary',
  'Buena Vista Elementary',
  'Cactus Medical, Health and Technology Magnet Academy',
  'Chaparral Prep Academy',
  'Cimarron Elementary',
  'David G. Millen Law and Government Magnet Academy',
  'Desert Rose Elementary',
  'Desert Willow Fine Arts, Science and Technology Magnet Academy',
  'Dos Caminos Dual Immersion',
  'Golden Poppy Elementary',
  'Innovations Academy of Palmdale',
  'Joshua Hills Elementary',
  'Los Amigos - Dual Immersion',
  'Manzanita Elementary',
  'Mesquite Elementary',
  'Oak Tree Community Day',
  'Ocotillo Elementary',
  'Palm Tree Elementary',
  'Palmdale Academy Charter',
  'Palmdale Aerospace Academy',
  'Palmdale Discovery Center',
  'Palmdale Learning Plaza',
  'Quail Valley Elementary',
  'Shadow Hills Engineering and Design Magnet Academy',
  'Space Aeronautics Gateway to Exploration Magnet Academy',
  'Summerwind Elementary',
  'Tamarisk Elementary',
  'Tumbleweed Elementary',
  'Yellen Learning Center',
  'Yucca Elementary',
  'Cornerstone at Pedregal Elementary',
  'Dapplegray Elementary',
  'Lunada Bay Elementary',
  'Mira Catalina Elementary',
  'Miraleste Intermediate',
  'Montemalaga Elementary',
  'Palos Verdes Adult',
  'Palos Verdes Distance Learning Academy',
  'Palos Verdes High',
  'Palos Verdes Intermediate',
  'Palos Verdes Peninsula High',
  'Point Vicente Elementary',
  'Rancho del Mar High (Continuation)',
  'Rancho Vista Elementary',
  'Ridgecrest Intermediate',
  'Silver Spur Elementary',
  'Soleado Elementary',
  'Sunrise Pre-School',
  'Vista Grande Elementary',
  'Abraham Lincoln',
  'Alondra Middle',
  'Buena Vista High',
  'Captain Raymond Collins',
  'Frank J. Zamboni',
  'Harry Wirtz Elementary',
  'Hollydale',
  'Howard Tanner',

  'Leona Jackson',
  'Los Cerritos',
  'Major Lynn Mokler',
  'Mark Keppel',
  'Odyssey STEM Academy',
  'Paramount Adult',
  'Paramount High',
  'Paramount Park Middle',
  'Paramount Unified Community Day',
  'Paramount Virtual Academy',
  'Theodore Roosevelt',
  'Wesley Gaines',
  'Altadena Elementary',
  'Aveson Global Leadership Academy',
  'Aveson School of Leaders',
  'Blair High',
  'Charles W. Eliot Middle',
  'CIS Academy',
  'Don Benito Fundamental',
  'Field (Eugene) Elementary',
  'Hamilton Elementary',
  'John Muir High',
  'Learning Works',
  'Longfellow (Henry W.) Elementary',
  'Madison Elementary',
  'Marshall Fundamental',
  'Mary W. Jackson STEAM Multilingual Magnet Elementary',
  'McKinley',
  'Norma Coombs Elementary',
  'OCS - South',
  'Octavia E. Butler Magnet',
  'Pasadena High',
  'Pasadena Rosebud Academy',
  'Rose City High (Continuation)',
  'San Rafael Elementary',
  'Sierra Madre Elementary',
  'Sierra Madre Middle',
  'Twilight Adult',
  'Washington Elementary STEM Magnet',

  'Willard Elementary',
  'Adult and Career Education',
  'Alcott Elementary',
  'Allison Elementary',
  'Armstrong Elementary',
  'Arroyo Elementary',
  'Barfield Elementary',
  'Cortez Elementary',
  'Decker Elementary',
  'Diamond Ranch High',
  'Emerson Middle',
  'Fremont Academy of Engineering and Design',
  'Ganesha High',
  'Garey High',
  'Golden Springs Elementary',
  'Harrison Elementary',
  'Kellogg Polytechnic Elementary',
  'Kingsley Elementary',
  'La Verne Science and Technology Charter',
  'Lexington Elementary',

  'Lopez Elementary',
  'Lorbeer Middle',
  'Madison Elementary',
  'Marshall Middle',
  'Pantera Elementary',
  'Park West High (Continuation)',
  'Philadelphia Elementary',
  'Pomona Alternative',
  'Pomona High',
  'Ranch Hills Elementary',

  'San Jose Elementary',
  'School of Arts and Enterprise',
  'School of Extended Educational Options',
  'Simons Middle',
  'Vejar Elementary',
  'Village Academy High School at Indian Hill',

  'Westmont Elementary',
  'Adams Middle',
  'Alta Vista Elementary',
  'Beryl Heights Elementary',
  'Birney Elementary',
  'Jefferson Elementary',

  'Madison Elementary',
  'Parras (Nick G.) Middle',
  'Patricia Dreizler Continuation High',
  'Redondo Beach Learning Academy',
  'Redondo Beach Virtual Learning',
  'Redondo Union High',
  'South Bay Adult',
  'Tulita Elementary',

  'Emma W. Shuey Elementary',
  'Encinita Elementary',
  'Mildred B. Janson Elementary',
  'Muscatel Middle',
  'Savannah Elementary',
  'Alvarado Intermediate',
  'Blandford Elementary',
  'Farjardo - La Seda Preschool',
  'Giano Intermediate',
  'Hollingworth Elementary',
  'Hurley Elementary',
  'iQ Academy California-Los Angeles',
  'Jellick Elementary',
  'John A. Rowland High',
  'Killian Elementary',
  'Nogales High',
  'Northam Elementary',
  'Rorimer Elementary',
  'Rowland Adult and Community Education',
  'Rowland Assistive Technology Academy',
  'Rowland Elementary',
  'Rowland Unified Community Day',
  'Rowland Virtual Learning Academy',
  'Santana High (Continuation)',
  'Shelyn Elementary',
  'Stanley G. Oswalt Academy',
  'Telesis Academy of Science & Math',
  'Villacorta Elementary',
  'Ybarra Academy for the Arts and Technology',
  'Yorbita Elementary',
  'San Antonio ROP',
  'Coolidge Elementary',
  'Del Mar High',
  'Gabrielino High',

  'McKinley Elementary',
  'Options for Youth San Gabriel',
  'Roosevelt Elementary',

  'Wilson Elementary',
  'San Gabriel Valley Regional Occupational Program',

  'Huntington Middle',
  'San Marino High',
  'Valentine Elementary',
  'Edison Elementary',
  'Franklin Elementary',
  'Grant Elementary',

  'John Muir Elementary',
  'Lincoln Middle',
  'Malibu Elementary',
  'Malibu High',
  'Malibu Middle',

  'Olympic High (Continuation)',
  'Roosevelt Elementary',
  'Santa Monica Alternative (K-8)',
  'Santa Monica High',
  'Santa Monica-Malibu Adult',
  'Santa Monica-Malibu Preschool',

  'Will Rogers Elementary',
  'Bridgeport Elementary',
  'Cedarcreek Elementary',
  'Charles Helmers Elementary',
  'Emblem Academy',
  'Highlands Elementary',
  'James Foster Elementary',
  'Mountainview Elementary',
  'North Park Elementary',
  'Plum Canyon Elementary',

  'Rosedell Elementary',
  'Santa Clarita Elementary',
  'Skyblue Mesa Elementary',
  'Tesoro del Valle Elementary',
  'West Creek Academy',
  'Academia Avance Charter',
  'Los Angeles College Prep Academy',
  'New West Charter',
  'Arroyo Vista Elementary',
  'Marengo Elementary',
  'Monterey Hills Elementary',
  'South Pasadena Middle',
  'South Pasadena Senior High',
  'Carmela Elementary',
  'Howard J. McKibben Elementary',
  'Lake Marie Elementary',
  'Loma Vista Elementary',

  'Richard L. Graves Middle',
  'Southern California ROC',
  'Canyon Springs Community Elementary',
  'Fair Oaks Ranch Community',
  'Golden Oak Community',
  'Leona H. Cox Community Elementary',
  'Mint Canyon Community Elementary',
  'Mitchell Community Elementary',
  'Pinetree Community Elementary',
  'Sulphur Springs Community Elementary',
  'Sulphur Springs Special Education Consortia',
  'Valley View Community Elementary',
  'Cloverly Elementary',
  'Dr. Doug Sears Learning Center',
  'Emperor Elementary',
  'La Rosa Elementary',
  'Longden Elementary',
  'Oak Avenue Intermediate',
  'Temple City Adult Education',
  'Temple City Alternative',
  'Temple City Early Learning Academy (TCELA)',
  'Temple City High',
  'Anza Elementary',
  'Arlington Elementary',
  'Bert M. Lynn Middle',
  'Calle Mayor Middle',
  'Casimir Middle',
  'Edward J. Richardson Middle',
  'Evelyn Carr Elementary',
  'Fern Elementary',
  'Gene Drevno Community Day',
  'Hamilton Adult Education Center',
  'Hickory Elementary',
  'Howard Wood Elementary',
  'J. H. Hull Middle',
  'Jefferson Middle',
  'John Adams Elementary',
  'Joseph Arnold Elementary',
  'LAUNCH Preschool',

  'Madrona Middle',
  'North High',
  'Owen H. Griffith Adult',
  'Philip Magruder Middle',
  'Riviera Elementary',
  'Seaside Elementary',
  'Shery (Kurt T.) High (Continuation)',
  'South High',
  'Torrance Elementary',
  'Torrance High',
  'Towers Elementary',
  'Victor Elementary',
  'Walteria Elementary',
  'West High',
  'Yukon Elementary',
  'Tri-Cities ROP',
  'Dean L. Shively',
  'New Temple',
  'Castle Rock Elementary',
  'Chaparral Middle',
  'Collegewood Elementary',
  'Cyrus J. Morris Elementary',
  'Diamond Bar High',
  'Evergreen Elementary',
  'Leonard G. Westhoff Elementary',
  'Maple Hill Elementary',
  'Quail Summit Elementary',
  'Ron Hockwalt Academies (Continuation)',
  'South Pointe Middle',
  'Suzanne Middle',
  'Vejar Elementary',
  'Walnut Elementary',
  'Walnut High',
  'Walnut Valley Adult',

  'California Virtual Academy @ Los Angeles',
  'Cameron Elementary',
  'Coronado High (Continuation)',
  'Edgewood High',
  'Edgewood Middle',
  'Hollencrest Middle',
  'Merced Elementary',
  'Merlinda Elementary',
  'Monte Vista Elementary',
  'Mt. SAC Early College Academy at West Covina',
  'Orangewood Elementary',
  'San Jose Charter Academy',
  'Vine Elementary',
  'Walnut Grove Intermediate',
  'Wescove Elementary',
  'West Covina High',
  'Anaverde Hills',
  'Cottonwood Elementary',
  'Del Sur Senior Elementary',
  'Esperanza Elementary',
  'Gregg Anderson Academy',
  'Hillview Middle',
  'Joe Walker Middle',
  'Leona Valley Elementary',
  'Quartz Hill Elementary',
  'Sundown Elementary',
  'Valley View Elementary',
  'Christian Sorensen Science Academy',
  'Daniel Phelan Language Academy',
  'Early Childhood Education Center',
  'Katherine Edwards Middle School STEAM & Dual Immersion Academy',

  'Lou Henry Hoover School of Fine Arts',
  'Lydia Jackson College Preparatory Academy',
  'Mill School and Technology Academy',
  'Orange Grove Elementary',
  'Wallen L. Andrews',
  'Walter F. Dexter Middle',
  'West Whittier Elementary',
  'Whittier Independent Study',
  'Adult Division Center',
  'California High',
  'Frontier High (Continuation)',
  'La Serna High',
  'Pioneer High',
  'Santa Fe High',
  'Sierra Vista High (Alternative)',
  'Whittier High',
  'Academy of the Canyons',
  'Arroyo Seco Junior High',
  'Bowman (Jereann) High (Continuation)',
  'Canyon High',
  'Castaic High',
  'Golden Oak Adult',
  'Golden Valley High',
  'La Mesa Junior High',
  'Learning Post Academy (Alternative)',
  'Mission View Public',
  'Opportunities for Learning - William S. Hart',
  'Placerita Junior High',
  'Rancho Pico Junior High',
  'Rio Norte Junior High',
  'Santa Clarita Valley International',
  'Saugus High',
  'Sierra Vista Junior High',
  'Valencia High',
  'West Ranch High',
  'William S. Hart High',
  'Challenger Middle',
  'Vista San Gabriel Elementary',
  'Wilsona Achievement Academy',
  'Aviation Elementary',
  'Da Vinci Communications',
  'Da Vinci Connect',
  'Da Vinci Design',
  'Da Vinci Science',
  'Del Aire Elementary',
  'Hollyglen Elementary',
  'Success Learning Center',
  'Wiseburn Middle',
];

const SCHOOL_LIST = RAW_SCHOOL_LIST.filter((value, index, self) => self.indexOf(value) === index);

export default SCHOOL_LIST;
