import React, { useEffect, useState } from 'react';
import {
  faHouse,
  faPersonMilitaryPointing,
  faPenToSquare,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';
import { UserSession } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from 'store/slices/userSessionSlice';
import {
  getHomePath,
  getSealsAppPath,
  getSealsProgramDescriptionPath,
  getTxtLabsApprenticeshipAppPath,
  getTxtLabsApprenticeshipDescriptionPath,
} from 'utils/navigation';
import { faReadme } from '@fortawesome/free-brands-svg-icons';
import { useAppSelector } from '../store/hooks';

export type Link = {
  title: string;
  icon: JSX.Element;
  to: string;
  type: 'internal' | 'external';
};

/**
 * Interface side menu dropdown links
 */
export type DropdownLink = {
  title: string;
  icon: JSX.Element;
  active: boolean;
  children: Link[];
};

export type NavigationLink = {
  type: 'dropdown' | 'single';
  data: Link | DropdownLink;
};

/**
 * Side Menu links
 */
const LINKS: NavigationLink[] = [
  {
    type: 'single',
    data: {
      title: 'Home',
      icon: <FontAwesomeIcon icon={faHouse} />,
      to: getHomePath(),
      type: 'internal',
    },
  },

  {
    type: 'dropdown',
    data: {
      title: 'SEALS',
      icon: <FontAwesomeIcon icon={faPersonMilitaryPointing} />,
      active: false,
      children: [
        {
          title: 'Program Description',
          icon: <FontAwesomeIcon icon={faReadme} />,
          to: getSealsProgramDescriptionPath(),
          type: 'internal',
        },
        {
          title: 'Application',
          icon: <FontAwesomeIcon icon={faPenToSquare} />,
          to: getSealsAppPath(),
          type: 'internal',
        },
      ],
    },
  },
  {
    type: 'dropdown',
    data: {
      title: 'TXT Labs',
      icon: <FontAwesomeIcon icon={faLaptop} />,
      active: false,
      children: [
        {
          title: 'Apprenticeship',
          icon: <FontAwesomeIcon icon={faReadme} />,
          to: getTxtLabsApprenticeshipDescriptionPath(),
          type: 'internal',
        },
        {
          title: 'Apprenticeship Application',
          icon: <FontAwesomeIcon icon={faPenToSquare} />,
          to: getTxtLabsApprenticeshipAppPath(),
          type: 'internal',
        },
      ],
    },
  },
];

export default function useNavigationLinks() {
  const [links, setLinks] = useState<NavigationLink[]>([]);

  const user = useAppSelector(getUser);

  useEffect(() => {
    let mounted = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function filterLinks(userData: UserSession) {
      setLinks(LINKS);
    }

    if (mounted && user) filterLinks(user);

    return () => {
      mounted = false;
    };
  }, [user]);

  const toggleLinkDropdown = (link: DropdownLink) => {
    // eslint-disable-next-line no-param-reassign
    link.active = !link.active;
    setLinks([...links]);
  };

  return { links, toggleLinkDropdown };
}
