
export const getLoginPath= () => '/';

export const getHomePath = () => '/home';

export const getSealsProgramDescriptionPath = () => '/programs/seals';
export const getSealsAppPath = () => '/programs/seals/application';
export const getSealsAppBackgroundPath = () => '/programs/seals/application/background';
export const getSealsAppSchoolPath = () => '/programs/seals/application/school';
export const getSealsAppFamilyPath = () => '/programs/seals/application/family';
export const getSealsAppPromptsPath = () => '/programs/seals/application/prompts';
export const getSealsAppTranscriptsPath = () => '/programs/seals/application/transcripts';
export const getSealsAppSignaturePath = () => '/programs/seals/application/signature';

export const getUpdateProfilePath = () => '/profile';

export const getTxtLabsApprenticeshipDescriptionPath = () => '/txt-labs/apprenticeship';
export const getTxtLabsApprenticeshipAppPath = () => '/txt-labs/apprenticeship/application';