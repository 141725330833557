import React, { useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim'; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import styled from 'styled-components';
import { COLOR_PRIMARY } from 'constants/colors';

const Container = styled.div`
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: ${COLOR_PRIMARY};
  position: fixed;
  z-index: -1;

  #tsparticles {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${COLOR_PRIMARY};
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
`;
export default function ParticlesBackground() {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async engine => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      // await loadAll(engine);
      // await loadFull(engine);
      await loadSlim(engine);
      // await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async () => {
    // console.log(container);
  };

  const options = useMemo(
    () => ({
      detectRetina: true,
      fpsLimit: 30,
      background: {
        position: '50% 50%',
        repeat: 'no-repeat',
        size: 'cover',
        opacity: 1,
      },
      interactivity: {
        detectsOn: 'canvas' as any,
        events: {
          resize: true as any,
        },
      },
      particles: {
        color: {
          value: '#fff',
        },
        line_linked: {
          color: '#fff',
          distance: 150,
          enable: true,
          opacity: 0.4,
          width: 1,
        },
        links: {
          value: '#fff',
          width: 2,
          distance: 200,
          opacity: 0.5,
          enable: true,
        },
        number: {
          value: 100,
        },
        opacity: {
          random: {
            enable: false,
          },
          value: 1,
        },
        size: {
          value: 4,
          animation: {
            enable: true,
            minimumValue: 0.5,
            speed: 10,
          },
          random: {
            enable: true,
          },
        },
        collisions: {
          enable: false,
        },
        move: {
          speed: 1,
          enable: true,
          outMode: 'bounce',
        },
      },
    }),
    [],
  );

  if (init) {
    return (
      <Container>
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      </Container>
    );
  }

  return null;
}
