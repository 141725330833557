/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ParticlesBackground from 'components/Particles/ParticlesBackground';
import styled from 'styled-components';
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  /* overflow: hidden; */
  position: relative;
`;

const Overlay = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 1;
`
export function AuthRoutes() {
  return (
    <StyledContainer>
      <ParticlesBackground />
      <Overlay>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Overlay>
    </StyledContainer>
  );
}
