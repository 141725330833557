import { Form } from 'rsuite';
import React, { useState, forwardRef } from 'react';
import { FormFieldSize } from 'components/Form/CustomForm';
import styled from 'styled-components';
import { COLOR_GREEN } from 'constants/colors';
import FileUploader from 'components/Elements/FileUploader/FileUploader';
import { FileType } from 'rsuite/esm/Uploader';
import serverErrorHandler from 'services/serverError.service';
import { openSuccessToaster } from 'services/toast.service';
import { useAppSelector } from 'store/hooks';
import { getUser } from 'store/slices/userSessionSlice';
import { url } from 'constants/api.constants';
import FormControlLabel from '../../Control/FormControlLabel';

export type Activity = {
  name: string;
  position: string;
  hours: string;
  grade: string;
};

type ActivitiesInputControlProps = {
  value: Activity[];
  onChange: any;
};

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

function UploadFieldInputControl({
  value,
  onChange,
}: // fieldError,
ActivitiesInputControlProps) {
  const user = useAppSelector(getUser);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileType[]>([]);

  const onError = (e: any) => {
    if (e.response) {
      serverErrorHandler({ response: { data: e.response } });
    } else {
      serverErrorHandler(e);
    }
    setIsLoading(false);
  };

  const onSuccess = (response: any) => {
    onChange([...value, response.payload]);
    openSuccessToaster('File uploaded.', 3000);
    setIsLoading(false);
  };

  const onRemove = async (file: FileType) => {
    try {
      setIsLoading(true);
      onChange(
        value.filter((item: any) => item.originalName !== file.name),
      );
      setIsLoading(false);
      openSuccessToaster(`Removed file.`, 3000);
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  return (
    <div>
      <FileUploader
        setIsLoading={setIsLoading}
        files={files}
        isLoading={isLoading}
        onRemove={onRemove}
        onError={onError}
        onSuccess={onSuccess}
        url={`${url.API_URL}/txt-labs/apprenticeship/application/resume/${
          user?._id || ''
        }`}
      />
    </div>
  );
}

const UploadApprenticeResumeField = forwardRef((props: any, ref: any) => {
  const { name, size, helpMessage, label, required } = props;
  const showRequired: boolean = required || false;

  return (
    <FormGroup ref={ref} size={size}>
      <FormControlLabel required={showRequired}>{label} </FormControlLabel>
      <ControlWrapper>
        <Form.Control name={name} accepter={UploadFieldInputControl} />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default UploadApprenticeResumeField;
