/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'utils/storage';
import { UserSession } from 'types';
import { IUpdateProfile } from 'features/profile/api/updateProfile';
import { RootState } from '../store';

export interface IUserSessionState {
  user: UserSession | null;
  storedEmailed: string;
  history: string[];
}

const initialState: IUserSessionState = {
  user: null,
  storedEmailed: storage.getLoginEmail() || '',
  history: [],
};

export const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // stores login email in storage and redux store
    setStoredEmail: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.storedEmailed = action.payload;
        storage.setLoginEmail(action.payload);
      }
    },
    // clear login email from storage and redux store
    clearStoredEmail: state => {
      storage.clearLoginEmail();
      state.storedEmailed = '';
    },

    setUser: (state, action: PayloadAction<UserSession>) => {
      if (action.payload) {
        state.user = action.payload;
      }
    },

    setUserProfileImage: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        if (state.user) {
          state.user.profileImage = action.payload;
        }
      }
    },
    setUserProfile: (state, action: PayloadAction<IUpdateProfile>) => {
      if (action.payload) {
        if (state.user) {
          state.user = { ...state.user, ...action.payload };
        }
      }
    },
    // clear redux store of user data
    logout: state => {
      state.user = null;
    },
  },
});

export const {
  setUserProfile,
  setStoredEmail,
  clearStoredEmail,
  logout,
  setUser,
  setUserProfileImage,
} = userSessionSlice.actions;

// accessor for admin user
export const getUser = (state: RootState) => state.currentUser.user;

// get stored email
export const getEmail = (state: RootState) =>
  state.currentUser.storedEmailed;

// get navigation history
export const getHistory = (state: RootState) => state.currentUser.history;

export default userSessionSlice.reducer;
