import React from 'react';
import styled from 'styled-components';
import SignUpForm from '../components/SignUpForm';

const StyledContainer = styled.div`
  padding: 50px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

function SignUp() {
  return (
    <StyledContainer>
      <SignUpForm />
    </StyledContainer>
  );
}

export default SignUp;
