import { EnhancedStore } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import { UserSession } from 'types';
import { IUpdateProfileForm } from 'features/profile/hooks/useUpdateProfile';
import { ISideMenuState, toggle } from './slices/sideMenuSlice';
import { IUserSessionState, logout, setUser, setUserProfile, setUserProfileImage } from './slices/userSessionSlice';

let store: EnhancedStore<
  {
    currentUser: IUserSessionState;
    sideMenu: ISideMenuState;
  },
  AnyAction,
  [
    ThunkMiddleware<
      {
        currentUser: IUserSessionState;
        sideMenu: ISideMenuState;
      },
      AnyAction,
      undefined
    >,
  ]
>;

export const injectStore = (
  _store: EnhancedStore<
    {
      currentUser: IUserSessionState;
      sideMenu: ISideMenuState;
    },
    AnyAction,
    [
      ThunkMiddleware<
        {
          currentUser: IUserSessionState;
          sideMenu: ISideMenuState;
        },
        AnyAction,
        undefined
      >,
    ]
  >,
) => {
  store = _store;
};

export const dispatchLogout = () => {
  store.dispatch(logout());
};

export const dispatchSideMenuToggle = () => {
  store.dispatch(toggle());
};

export const dispatchSetUser = (user: UserSession) => {
  store.dispatch(setUser(user));
};

/**
 * Updates logged in user's profile image
 * @param url 
 */
export const dispatchSetUserProfileImage = (url:string) => {
  store.dispatch(setUserProfileImage(url));
};

/**
 * Updates user profile info
 * @param url 
 */
export const dispatchSetUserProfile = (data:IUpdateProfileForm) => {
  store.dispatch(setUserProfile(data));
};
