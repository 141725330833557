import styled from 'styled-components';
import React from 'react';
import useUrlQuery from 'hooks/useUrlQuery';
import ResetPasswordForm from '../components/ResetPasswordForm';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const FormCol = styled.div`
  flex: 1;
  display: block;
  min-height: 100vh;
  overflow: hidden;
  padding: 0px;
  padding-top: 75px;
  padding-bottom: 25px;
`;


function ResetPassword() {
  // get url query
  const query = useUrlQuery();
  // get reset code from url
  const token = query.get('oobCode');
  const userId = query.get('userId');

  if (token && userId) {
    return (
      <StyledContainer>
        <FormCol>
          <ResetPasswordForm token={token} />
        </FormCol>
      </StyledContainer>
    );
  }

  return <div>ssd</div>;
}

export default ResetPassword;
