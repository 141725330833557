import LoginForm from 'features/auth/components/LoginForm';
import styled from 'styled-components';
import React from 'react';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const RightCol = styled.div`
  flex: 1;
  display: block;
  min-height: 100vh;
  overflow: hidden;
  padding: 0px;
  padding-top: 75px;
  padding-bottom: 25px;
`;

/**
 * Renders a view with a welcome message and Login
 * Form. Users are able to sign in with their credentials
 * @returns
 */
function Login() {
  return (
    <StyledContainer>
      <RightCol>
        <LoginForm />
      </RightCol>
    </StyledContainer>
  );
}

export default Login;
