import styled from 'styled-components';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomForm from 'components/Form/CustomForm';
import useForgotPassword from '../hooks/useForgotPassword';

const Container = styled.div`
  max-width: 100%;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border-radius: 10px;
  max-width: 400px;
  padding: 20px 16px;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const CustomLink = styled(NavLink)`
  p {
    font-size: 15px;
    color: var(--color-blue);

    @media (min-width: 992px) {
      &:hover {
        color: var(--color-black);
      }
    }
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 400px;
`;

const Title = styled.h1`
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 25px;
  line-height: 21px;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
`;

const Warn = styled.p`
  font-size: 16px;
  font-weight: 400;

  margin-bottom: 25px;
`;

function ForgotPasswordForm() {
  // get sign in form state
  const { fields, formValue, isLoading, model, onChange, onSubmit } =
    useForgotPassword();

  return (
    <Container>
      <FormHeader>
        <Title>Forgot your password?</Title>
        <Message>
          Enter your profile email address and we will send you
          instructions to reset your password.
        </Message>
        <Warn>You may have to check your spam or junk folder.</Warn>
      </FormHeader>

      <CustomForm
        onChange={onChange}
        onSubmit={onSubmit}
        model={model}
        formValue={formValue}
        isLoading={isLoading}
        fields={fields}
        isDisabled={false}
        submitLabel="Send Password Reset Instructions"
        hasChanges={false}
        submitBtnSize="full"
      />

      <LinksContainer>
        <CustomLink to="/">
          <p>Return to login.</p>
        </CustomLink>
      </LinksContainer>
    </Container>
  );
}

export default ForgotPasswordForm;
