import { useNavigate } from 'react-router-dom';
import {
  getHomePath,
  getLoginPath,
  getSealsAppBackgroundPath,
  getSealsAppFamilyPath,
  getSealsAppPath,
  getSealsAppPromptsPath,
  getSealsAppSchoolPath,
  getSealsAppSignaturePath,
  getSealsAppTranscriptsPath,
  getTxtLabsApprenticeshipAppPath,
  getTxtLabsApprenticeshipDescriptionPath,
  getUpdateProfilePath,
} from 'utils/navigation';

export default function useAppNavigation() {
  const navigate = useNavigate();

  return {
    navToLogin: () => navigate(getLoginPath()),
    navToHome: () => navigate(getHomePath()),
    navToSealsApp: () => navigate(getSealsAppPath()),
    navToSealsAppBackground: () => navigate(getSealsAppBackgroundPath()),
    navToSealsAppSchool: () => navigate(getSealsAppSchoolPath()),
    navToSealsAppFamily: () => navigate(getSealsAppFamilyPath()),
    navToSealsAppPrompts: () => navigate(getSealsAppPromptsPath()),
    navToSealsAppTranscripts: () => navigate(getSealsAppTranscriptsPath()),
    navToSealsAppSignature: () => navigate(getSealsAppSignaturePath()),
    navToUpdateProfile: () => navigate(getUpdateProfilePath()),
    navToTxtLabsApprenticeshipDescription: () => navigate(getTxtLabsApprenticeshipDescriptionPath()),
    navToTxtLabsApprenticeshipApplication: () => navigate(getTxtLabsApprenticeshipAppPath()),
  };
}
